import { Table } from "react-bootstrap";

export const TermsAndConditions = () => {
  return (
    <body>
      <div>
        Housr Living Limited (“Housr”) - At Housr we try to make it very clear
        what you are signing up for. These Terms will apply to any contract made
        between you and Housr for the provision of Services. Please read the
        Terms carefully and make sure that you fully understand them before
        agreeing to purchase Our services. Please note that before completing
        the sign-up process, you will be asked to agree to these Terms.
      </div>
      <div className="mt-3">Last updated on 16 January 2024.</div>
      <div className="mt-3 fw-bold">Definitions and interpretation</div>
      <div className="mt-2">
        <b>Agents/Engineers</b> Agents or engineers appointed by Housr to assist
        in providing Services, including but not limited to installation to
        provide Services, meter reading, repair, upgrade or removal of meters
        and other equipment relating to Housr’s provision of Services.
      </div>
      <div className="mt-2">
        <b>Contract</b> The terms and conditions for supplying Services to the
        Premises between you and us, together with any prices and extra
        conditions for certain products or services and includes a Deemed
        Contract unless otherwise specified.
      </div>
      <div className="mt-2">
        <b>Customer</b> The Customer is the single tenant or a group of tenants
        if the Property is occupied; if the property is unoccupied, the Customer
        is the Owner of the Property. The Owner can chose to elect a letting
        agent to manage the Services, and in that case, the Customer will be the
        letting agent who acts on behalf of the Owner. Nothing in this
        definition shall limit the liability of the Owner or the tenant for any
        liability relating to the consumption and payment of the Services.
      </div>
      <div className="mt-2">
        <b>Deemed Contract</b> where Housr supplies energy to any premise
        otherwise than in pursuance of a contract, Housr shall be deemed to have
        contracted with the occupier (i.e. tenant) or, if unoccupied, the owner
        of the Premises, for the supply of energy from the time Housr began to
        supply Energy to the premises; this contract is deemed to have been made
        by virtue of Paragraph 3 of Schedule 6 of the Electricity Act 1989,
        Paragraph 8 of Schedule 2B of the Gas Act 1986 and Paragraph 3 of
        Schedule 4 of the Utilities Act 2000. Deemed contract is further set out
        in Clause 20.
      </div>
      <div className="mt-2">
        <b>Events Outside Our Control </b> Is defined in Clause 12.
      </div>
      <div className="mt-2">
        <b>Failed Payment Fee</b> Is defined in Clause 7.15.
      </div>
      <div className="mt-2">
        <b>Initial Cancellation Period</b> The Consumer Contracts (Information,
        Cancellation and Additional Charges) Regulations 2013 give Customers the
        right to cancel a contract within 14 days of signing up without giving a
        reason.
      </div>
      <div className="mt-2">
        <b>Late Payment Fee</b> Is defined in Clause 7.15
      </div>
      <div className="mt-2">
        <b>Meter</b> Devices and equipment used to measure Utilities usage and
        the consumption of energy at the Premises.
      </div>
      <div className="mt-2">
        <b>Minimum Term</b> The minimum period that each Service will be
        provided to the Property as specified in the Order contract. This will
        commence from the date Housr begins providing Services to You.
      </div>
      <div className="mt-2">
        <b>Owner</b> The overall Owner of the Property, whether freehold,
        leasehold or licensor to the Customer.
      </div>
      <div className="mt-2">
        <b>Order</b> The Order documentation and communications between us
        setting out Your order for the Services, at times referred to as Order
        contract.
      </div>
      <div className="mt-2">
        <b>Packages</b> The pre-packaged Utilities and Services bundles for
        Customers.
      </div>
      <div className="mt-2">
        <b>Party</b> Means either You or Us, and ‘Parties’ means You and Us.
      </div>
      <div className="mt-2">
        <b>Product</b> The package and product We create for You to provide
        Services, as set out in any Order.
      </div>
      <div className="mt-2">
        <b>Property / Premise</b> The Property and/or Premise (including any
        part of any land, building, space or structure) to which Housr provides
        Services under this Contract or which are shown in the Order.
      </div>
      <div className="mt-2">
        <b>Services</b> The services that We are providing to You as set out in
        the Order; this can include different combinations of services or
        standard packages.
      </div>
      <div className="mt-2">
        <b>Supplier(s)</b> Any third-party supplier Housr appoints or uses to
        supply Customers with Services.
      </div>
      <div className="mt-2">
        <b>Tenant</b> a person who occupies a Premise.
      </div>
      <div className="mt-2">
        <b>Terms</b> The terms and conditions set out in this document and the
        Order documents confirming the nature and form of the contract between
        You (the Customer) and Housr.
      </div>
      <div className="mt-2">
        <b>Third-Party Finance Provider</b> An individual or company used by the
        Customer to pay their Fees and who guarantees the Customer’s
        financial/payment obligations under these Terms and Order documents.
      </div>
      <div className="mt-2">
        <b>We, Us and Our:</b> Reference to Housr Living Ltd (13320192), having
        its registered office at 65 Harvest Way, Nuneaton, England, CV10 0FJ and
        any business representatives, person, corporation, firm organisation or
        entity appointed by Housr and includes Us supplying services under Our
        other brands from time to time.
      </div>
      <div className="mt-2">
        <b>Website</b> Reference to www.housrbills.co.uk
      </div>
      <div className="mt-2">
        <b>Working Day</b> Any day other than a Saturday, a Sunday, or a bank
        holiday in England and Wales.
      </div>
      <div className="mt-2">
        <b>Ultimate beneficiary</b> Refers to the person or entity that is the
        ultimate beneficiary and end users of the Services; this is the tenants
        if a Premise is occupied and the owner of the Premise if the Premise is
        unoccupied.
      </div>
      <div className="mt-2">
        <b>Utilities</b> Utilities relevant to letting and accommodation, e.g.
        gas, electricity, water, and Broadband.
      </div>
      <div className="mt-2">
        <b>You and Your</b> Reference to the Customer.
      </div>
      <div className="mt-3">
        In this Agreement, words in the singular include the plural and, in the
        plural, include the singular.
      </div>
      <div className="mt-2">
        Unless the context otherwise requires, a reference to one gender shall
        include a reference to the other gender.
      </div>
      <div className="mt-2">
        References to a statute or statutory provision are references to the
        statute or statutory provision that is in force for the time being,
        taking account of any amendment, extension or re-enactment and includes
        any subordinate legislation made under it.
      </div>
      <div className="mt-2">
        A “person” includes a corporate or unincorporated body (whether having a
        separate legal personality or not).
      </div>
      <div className="mt-2">
        Any phrase introduced by the words “including”, “includes”, “in
        particular”, “or”, “for example” or “similar” shall be construed as
        illustrative and shall not limit the generality of related general
        words.
      </div>
      <div className="mt-2">
        Any use of the words "writing" or "written" in these Terms will include
        email unless otherwise stated.
      </div>
      <div className="mt-4 fw-bold">1. Key Terms</div>
      <div className="mt-3">
        1.1. Acceptance of these terms forms a binding contract between You and
        Us as set out in Clause 3.
      </div>
      <div className="mt-3">
        1.2. We reserve the right to amend these Terms from time to time as set
        out in Clause 4.
      </div>
      <div className="mt-3">
        1.3. You agree to pay Us for Services We provide under these Terms once
        a contract is formed between us, in accordance with Clause 7 and 8. This
        includes any additional charges as laid out in the aforementioned
        Clauses.
      </div>
      <div className="mt-3">
        1.4. If You are arranging for a third party to pay for Your services on
        Your behalf, they will be bound by these same Terms, as stated in Clause
        9.
      </div>
      <div className="mt-3">
        1.4.1. Please note that the Ultimate Beneficiary of the services is
        liable for payment of the services if the third party fails to pay for
        whatever reason, including, without limitation, a voluntary arrangement
        or legal proceedings or other steps taken in relation to the third
        party’s winding-up or liquidation; the ååappointment of a liquidator,
        receiver, administrator, administrative receiver or similar officer in
        respect of the third party or its assets; or a composition, assignment
        or arrangement with the third party’s creditors.
      </div>
      <div className="mt-3">
        1.4.2. For clarity, the Ultimate Beneficiary will be the tenants if the
        Premise is occupied and the owner if the Premise is unoccupied, and a
        third party may be a letting agent.
      </div>
      <div className="mt-3">
        1.4.3. Housr assumes no responsibility or liability in the event that a
        third party fails to remit payment for Services. The Ultimate
        Beneficiary is still obligated to settle payment with us; however, they
        have the option to pursue legal action against the third party if the
        latter fails to fulfil their payment obligation, provided that the
        Ultimate Beneficiary has already made payment to the third party.
      </div>
      <div className="mt-3">
        1.5. You may cancel this contract within 14 days of sign-up in
        accordance with Your statutory rights. Beyond that time, cancellation
        will be in accordance with the Terms set out in Clause 13-.
      </div>
      <div className="mt-3">
        1.6. If there is a requirement for repair or maintenance to any of the
        services We agree to provide, payment expectation from You will be
        suspended under Clause 5.7 until this is rectified. You will not be
        expected to pay Us for repairing Your Services under Clause 7.1. unless
        an appointment is missed or damage is caused wilfully.
      </div>
      <div className="mt-3">
        1.7. If/when Your contract with Us ends or is cancelled by either Party,
        We reserve the right to retain any amount or deposit for outstanding
        fees as set out in Clauses 13 and 14. If Your account is in credit at
        the end of Your contract, We will return surplus funds to You as set out
        in Clauses 13 and 14.
      </div>
      <div className="mt-3">
        1.8. You consent under these Terms to allow us We to pass your details
        on to third parties providing services for You, without limiting your
        statutory right to object as detailed in Clause 16.
      </div>
      <div className="mt-3 fw-bold">2. Our Identity</div>
      <div className="mt-3">
        Housr Living Ltd (“Housr”) is a company providing management of
        utilities, telecoms and other services registered in England and Wales,
        Company Number 13320192. Our Company’s registered office is at:
      </div>
      <div className="mt-3">
        <ul style={{ listStyleType: "none" }} className="ps-0">
          <li>65 Harvest Way</li>
          <li>Nuneaton</li>
          <li>England</li>
          <li>CV10 0FJ</li>
        </ul>
      </div>
      <div className="mt-3 fw-bold">3. The Contract With You</div>
      <div className="mt-3">
        3.1. These terms and conditions and Our applicable tariff information
        that you are given during your sign-up shall form the Contract between
        You and Housr for the supply of Services to the Premises.
      </div>
      <div className="mt-3">
        3.2 These terms will be binding once the first Customer signs the order
        and You begin receiving Housr services.
      </div>
      <div className="mt-3">
        3.3. Please ensure that You read these Terms carefully, and check that
        the details on the Order and in these Terms are complete and accurate
        before You sign or agree to the Order. If You think that there is a
        mistake, please contact Us to discuss it.
      </div>
      <div className="mt-3">
        3.4. When You sign and submit the Order to Us, this does not mean that
        We have accepted Your order for Services. Our acceptance of the Order
        will take place as described in Clause 3.5. If We are unable to supply
        You with the Services, We will inform You of this and We will not
        process the Order.
      </div>
      <div className="mt-3">
        3.5. These Terms will become a binding contract between You and Us when
        We contact You and confirm in writing that We are able to provide You
        with the Services required.
      </div>
      <div className="mt-3">
        3.6. By submitting the Order to Us, You accept responsibility for the
        Order in full. We have the right to bill You in full for the Order until
        other Tenants agree to Our Terms.
      </div>
      <div className="mt-3">
        3.7. If any of these Terms conflict with any term of the Order, the
        Order will take priority.
      </div>
      <div className="mt-3">
        3.8. If You have any existing Utilities and Service providers that will
        be replaced by Our services, You may need to contact Your existing
        Services provider(s) in order to terminate those Services. Your existing
        Services providers may charge cancellation fees in relation to their
        contract with You, and We accept no liability for this.
      </div>
      <div className="mt-3">
        3.9. Each Customer is a party to the Contract, and changes relating to
        service cancellation must be made in writing by each Customer and are
        only effective once Housr has confirmed the cancellation in writing.
      </div>
      <div className="mt-3">
        3.10. Where more than one Customer has entered into a contract and
        changes to the package are requested, all Customers must give their
        consent before any amendment is actioned by Us. This consent must be
        provided in writing either by letter or email.
      </div>
      <div className="mt-3 fw-bold">4. Changes to Order or Terms</div>
      <div className="mt-3">
        4.1. We reserve the right to revise these Terms from time to time in the
        following circumstances:
      </div>
      <div className="mt-3">
        4.1.1. Changes occur in relevant laws and regulatory requirements.
      </div>
      <div className="mt-3">
        4.1.2. We deem this appropriate in relation to Our business.
      </div>
      <div className="mt-3">
        4.2. If We have to revise these Terms under Clause 4.1, We will give You
        at least one month's written notice of any changes to these Terms before
        they take effect. You can choose to cancel the contract in accordance
        with Clause 13.
      </div>
      <div className="mt-3">
        4.3. You may make a change to the Order for Services at any time before
        the start date of the Services by contacting Us. Where this means a
        change in the total price of the Services, We will notify You of the
        amended price in writing. You can choose to cancel the Order in
        accordance with Clause 13.1 in these circumstances.
      </div>
      <div className="mt-3">
        4.4. If You wish to cancel an Order before it has been fulfilled, please
        see Your right to do so in Clause 13.
      </div>
      <div className="mt-3 fw-bold">5. Providing the Services</div>
      <div className="mt-3">
        5.1. We will make reasonable endeavours to supply the Services to You
        from the date agreed between Us in the Order. The estimated start time
        for providing Services will vary between the types of Services. We may
        be unable to fulfil Your order if sufficient payment information has not
        been added to Your account.
      </div>
      <div className="mt-3">
        5.2. We will make commercially reasonable best efforts to connect and
        supply the Services on time. Please ensure You do not miss any
        installation visits from Our Agents/Engineers. We reserve the right to
        charge reasonable associated administration costs if You miss
        installation visits as laid out in Clause 7.11. There may, however, be
        delays if:
      </div>
      <div className="mt-3">
        5.2.1 Existing suppliers prevent Us from moving Your Services; or
      </div>
      <div className="mt-3">
        5.2.2 We do not have all of the necessary information; or
      </div>
      <div className="mt-3">
        5.2.3 You have provided Us with incorrect information; or
      </div>
      <div className="mt-3">
        5.2.4 We need You to move Your Services to us; or
      </div>
      <div className="mt-3">
        5.2.5 An Event Outside Our Control has occurred. See Clause 12 for Our
        responsibilities when an Event Outside Our Control occurs.
      </div>
      <div className="mt-3">
        5.3. We will need certain information from You that is necessary for Us
        to provide the Services. This will be made clear on any Order forms and
        contracts. If You do not provide Us with this information or provide
        incomplete or incorrect information, We may charge an additional
        reasonable sum to cover any extra work that is required, and we reserve
        the right to suspend the Services by giving You written notice. We will
        not be liable for any delay or non-performance where You have not
        provided this information to Us after We have asked You to provide it.
        If We suspend the Services due to this, You do not have to pay for the
        Services while they are suspended but this does not affect Your
        obligation to pay for any invoices already sent.
      </div>
      <div className="mt-3">
        5.4. Repair/Maintenance/Suspension - We may have to suspend the Services
        while resolving technical problems or to make improvements to the
        Services which have been agreed between You and Us in writing. We will
        contact You to let You know in advance where this occurs unless the
        problem is urgent or is classified as an emergency. You do not have to
        pay for the Services while they are suspended under Clause 5.4, but this
        does not affect Your obligation to pay for any invoices already sent.
      </div>
      <div className="mt-3">
        5.5. The cost of Services will vary depending on the Package agreed
        between Us in writing for each Order. The individual cost breakdown for
        the Services will be provided to You in writing as part of any Order
        negotiations. We refer to the tariffs section of Our terms (Clause 24).
      </div>
      <div className="mt-3">
        5.6. Where We are providing Services to You, Your extent to use
        Utilities and Services will be estimated in the Order contract (see
        Clause 26 of Our terms for an indication of usage allowances). If it
        becomes apparent that You (or Your Customers) have exceeded these
        estimated usages, We reserve the right under these Terms and any Order
        contract to charge You the relevant Fees or pass onto You any additional
        charges incurred from third-party Utilities or Service providers.
      </div>
      <div className="mt-3">
        5.7. If You do not pay Us for the Services when arranged as set out in
        Clause 8, We may suspend the Services with immediate effect until any
        outstanding amounts have been paid in full (except where You dispute an
        invoice under Clause 8.4). This does not affect Our right to charge You
        interest under Clause 8.2. This Clause shall not apply to water and
        energy services.
      </div>
      <div className="mt-3">
        5.7.1. For energy supply, we can suspend or disconnect the supply if:
      </div>
      <div className="mt-3">5.7.1.a. You have not paid your bill on time;</div>
      <div className="mt-3">
        5.7.1.b. You are in material breach of the Contract;
      </div>
      <div className="mt-3">
        5.7.1.c. We have reason to believe your Metering Equipment may have been
        damaged or tampered with; and/ or
      </div>
      <div className="mt-3">
        5.7.1.d. We are required to do so by any law, code or agreement.
      </div>
      <div className="mt-3">
        5.7.2. If we disconnect the energy supply, you are required to pay:
      </div>
      <div className="mt-3">
        5.7.2.a. Our reasonable costs to disconnect the supply; and
      </div>
      <div className="mt-3">
        5.7.2.b. Our reasonable costs to reconnect the supply.
      </div>
      <div className="mt-3">
        5.8. If We design a Package which You agree to purchase, We will own the
        copyright, trade mark(s) and all other intellectual property rights in
        the Package and any drafts, drawings, images or illustrations We make in
        connection with it.
      </div>
      <div className="mt-3">
        5.9. When You request electricity or gas services from Us, it can take
        up to 21 business days for Us to start supplying You with either
        Service. This process may be delayed by items including, without
        limitation, missing meter information, debt on the previous supplier
        account or missing address information.
      </div>
      <div className="mt-3 fw-bold">6. Defects with the Service</div>
      <div className="mt-3">
        6.1. In the unlikely event that there is any defect with the Services,
        You agree that You will contact Us as soon as reasonably possible, and
        We will use every effort to repair or fix the defect as soon as
        reasonably practicable, subject to actions required by third-party
        Services and Utility providers.
      </div>
      <div className="mt-3">
        6.2. You will not have to pay for Us to repair or fix a defect with the
        Services under Clause 6.1, except where an appointment is missed or
        damage is caused wilfully or by negligence.
      </div>
      <div className="mt-3">
        6.3. As a consumer, You have legal rights in relation to Services not
        carried out with reasonable skill and care or if the materials and
        equipment We use are faulty or not as described. Advice about Your legal
        rights is available from Your local Citizens Advice Bureau, Ombudsman or
        Trading Standards office. Nothing in these Terms will affect Your
        statutory legal rights.
      </div>
      <div className="mt-3 fw-bold">7. Prices</div>
      <div className="mt-3">
        7.1. The price of the Services will be indicated in Our tariff guide at
        the time We confirm Your Order. In addition, We will maintain a price
        list which will be available to You at the point We take payment or
        provide a statement of payment and Fees which are due. Our prices may
        change at any time, and We reserve the right to change these as
        appropriate. This will be communicated in writing to You. Price changes
        will not affect Orders that We have already confirmed with You.
      </div>
      <div className="mt-3">
        7.2. These non-exempt from VAT service prices include VAT, and the
        specific VAT rate applied may differ between the Services in a Package.
        If the rate of VAT changes between the date of the Order and the date of
        delivery or performance, We will adjust the rate of VAT that You pay,
        unless You have already paid for the Services in full before the change
        in the rate of VAT takes effect.
      </div>
      <div className="mt-3">
        7.3. Your payment plan is dependent on the number of people that have
        signed up for the contract. If there are more/fewer people signed up on
        Your contract than was previously agreed at the outset of Your contract,
        We reserve the right to change Your monthly payments to reflect the
        number of people that have signed up or We may terminate or change the
        terms of the agreement.
      </div>
      <div className="mt-3">Gas and electricity</div>
      <div className="mt-3">
        7.4. Our tariffs may also include a standing charge for a meter/other
        metering equipment at Your property.
      </div>
      <div className="mt-3">
        7.5. When You start a contract with Us, You will pay an amount monthly
        in advance as per a payment plan (which will be based on an estimate of
        the gas and electricity consumption at Your property). You will only be
        charged for the energy You use, and We will then reconcile this payment
        plan with Your actual energy usage and settle the difference at the end
        of Your contract.
      </div>
      <div className="mt-3">
        7.6. We may change Your monthly payments for gas and electricity if We
        notice that You are using, or are likely to use, a different level of
        energy than was estimated at the start of Your contract. Payments will
        be set to attempt a zero balance at the end of Your contract period or,
        if You remain with Us, over the next twelve months.
      </div>
      <div className="mt-3">
        7.7. We may change Your payments if Your contract length is amended
        either by Us or You. The price change will reflect the latest and best
        estimate for the supply during the updated contract period based on
        estimates provided by an Energy Industry third-party data provider.
      </div>
      <div className="mt-3">
        7.8. The Start and End dates of Your contract should reflect the time
        period where You are the legal occupier or owner of the premises, and We
        may update Your contract to reflect the correct dates.
      </div>
      <div className="mt-3">Water and sewerage</div>
      <div className="mt-3">
        7.9 The water fees in your payment plan are calculated based on the
        average water charge in your local area, the size of your property and a
        variable bill splitting administration fee, at an average of £2.20 per
        site per week. If your water bill issued by your water provider exceeds
        20% of the fees in the Housr payment plan, we reserve the right to
        charge you for the additional amount.
      </div>
      <div className="mt-3">
        7.9.1. We are not responsible for the type of water billing at your
        property whether it is by meter or by a rateable or assessed value. We
        will pay the charges set by your water authority during your supply
        period.
      </div>
      <div className="mt-3">
        7.9.2. We are not responsible for the installation of a water meter.
      </div>
      <div className="mt-3">
        7.9.3 We are not responsible for covering the cost of water leaks and
        any additional charges we incur as a result will be passed on.
      </div>
      <div className="mt-3">
        7.9.4. Our administration fee for water service is £2.20 per week per
        house.
      </div>
      <div className="mt-3">Broadband</div>
      <div className="mt-3">
        7.10. The broadband fees are charged at a monthly rate and advertised at
        the point of sign-up. The rate varies depending on packages and the
        internet speed requested.
      </div>
      <div className="mt-3">
        7.10.1 If we supply a broadband dongle as part of your services, You are
        charged (i.e. your broadband fees commence) from the date we post the
        dongle to your address, and You are charged in accordance with the
        monthly broadband fee advertised at the start of your contract.
      </div>
      <div className="mt-3">
        7.10.2 If we do not supply a broadband dongle, You are charged from the
        date of installation of your broadband line.
      </div>
      <div className="mt-3">
        7.10.3 You are responsible for returning the dongle when your broadband
        line is installed. If You fail to return the dongle, You will be charged
        for the cost of missing equipment and You may be charged an additional
        broadband fee for dual-access to internet services.
      </div>
      <div className="mt-3">
        7.11. We will not charge for installation of broadband, unless a fibre
        to the premises (FTTP) is required.
      </div>
      <div className="mt-3">
        7.12. You will not be charged for appointments unless you miss
        appointments.
      </div>
      <div className="mt-3">
        7.13 The broadband service entails a minimum contract period of 10
        months, during which customers are billed for the full 10-month cost
        regardless of the installation date. This aligns with the standard
        practice in the internet supply industry, where minimum contract periods
        are enforced to ensure a steady revenue flow and cover the expenses
        associated with customer acquisition, maintenance and the necessary
        infrastructure for broadband provision. It is advisable for customers to
        thoroughly review and comprehend the terms and conditions of the
        contract before committing to the service to avoid any unforeseen
        billing discrepancies.
      </div>
      <div className="mt-3">
        7.13.1. Your broadband billing is not pro-rata which means You will be
        billed for the full month of broadband if Your contract ends or begins
        within that month.
      </div>
      <div className="mt-3">Cancelling Your Internet:</div>
      <div className="mt-3">
        7.14. You may cancel Your internet free of charge any time up to 48
        hours before the start of Your installation appointment. If You give Us
        less than 48 hours’ notice, a charge of up to £60 may be applied to Your
        account. Once the internet has been installed, You have entered a
        contract to keep the internet for the length of Your tenancy. Cancelling
        the internet once it is installed leads to, apart from in exceptional
        circumstances, high cancellation fees. These charges, which are applied
        by the internet provider, will be passed directly on to the customer.
      </div>
      <div className="mt-3">
        7.14.1. You will be responsible for returning any equipment we have sent
        to you, including without limitation, dongles and boosters. Where the
        equipment is not returned or if it is returned in poor condition, you
        will be charged for the cost of the equipment on your final statement.
      </div>
      <div className="mt-3">Missed appointment fee</div>
      <div className="mt-3">
        7.15. If You do not give Us two (2) Working Days’ notice that You cannot
        make an appointment where Our agents or engineers are due to attend Your
        property, We will charge You a missed appointment fee. This will be
        equal to the amount it costs Us to rearrange the appointment and will be
        passed on directly from the provider. If an engineer appointment is
        considered to be "failed" or arranged unnecessarily (for example if a
        fault is caused by wilful neglect, damage or by user error), We may then
        be charged for this by Our providers. In this circumstance, We reserve
        the right to pass these charges on to You in full.
      </div>
      <div className="mt-3">Failed Payment Fee and Late Payment Fee</div>
      <div className="mt-3">
        7.16. If Your monthly or termly scheduled payment fails, We will try to
        take the payment again within seven (7) calendar days. We will warn You
        about the failure via text and/or email. If the reattempt fails as well,
        we may apply a £15 failed payment fee to Your account. Additionally, if
        you are behind on Your payment plan and we have contacted You about it,
        we may apply a £15 late payment fee to Your account.
      </div>
      <div className="mt-3">Additional Fees</div>
      <div className="mt-3">
        7.17. We may also charge You for other reasonable costs incurred by us.
        These include:
      </div>
      <div className="mt-3">
        7.17.1. visiting Your property if We have already offered or tried to
        contact You by letter, phone or email, and You have refused or failed to
        do what We have asked You to do to meet these terms;
      </div>
      <div className="mt-3">
        7.17.2. where You interfere with Your meter or steal water; or
      </div>
      <div className="mt-3">
        7.17.3. the reasonable costs that We incur if You choose to pay Your
        fees by bank transfer or cheque; or
      </div>
      <div className="mt-3">
        7.17.4. the reasonable costs that We incur for processing Your payment
        if You choose to pay Your fees by credit card; or
      </div>
      <div className="mt-3">
        7.17.5. the reasonable costs We incur for sending Your bills via post
        instead of electronically.
      </div>
      <div className="mt-3">
        7.17.6. All Our prices and other charges are affected by UK tax or, if
        applicable, duty, including VAT at the appropriate rate.
      </div>
      <div className="mt-3 fw-bold">8. Payment</div>
      <div className="mt-3">
        8.1. Where We are providing Services to You, We may ask You to make an
        advance payment for one Month of the Services. Your rights to a refund
        on cancellation are set out in Clauses 13 and 14.
      </div>
      <div className="mt-3">
        8.1.1. If You sign up after Your contract has started, Your first
        payment will be taken either 10 Working Days or seven (7) Working Days
        after the sign-up date depending on Your payment method.
      </div>
      <div className="mt-3">
        8.1.2. Your first payment may include a catch-up payment for any months
        missed by a delayed or late sign-up.
      </div>
      <div className="mt-3">
        8.1.3. After Your initial payment, all remaining payments will be
        scheduled on the first (1st) of each month unless We agree otherwise in
        writing.
      </div>
      <div className="mt-3">
        8.2. If You do not make any payment due to Us by the due date for
        payment, We may charge interest to You on the overdue amount at the rate
        of three (3) percentage points per year above the Official Bank Rate of
        the Bank of England. This interest shall accrue on a daily basis from
        the due date until the date of actual payment of the overdue amount,
        whether before or after judgement. You must pay Us interest together
        with any overdue amount.
      </div>
      <div className="mt-3">
        8.3. If after discussions You are unable to pay Our fees or You do not
        contact Us if You are unable to make Your payments, We reserve the right
        to instruct debt collection agents to collect the outstanding fees on
        Our behalf. Should this take place, You will have to pay an extra amount
        to cover the reasonable costs of the debt collection agents with the
        applicable VAT.
      </div>
      <div className="mt-3">
        8.4. If, however, You dispute an invoice amount in good faith and
        contact Us to let Us know promptly, this Clause will not apply for the
        period of the dispute as long as You are regularly in touch with Us to
        resolve the dispute.
      </div>
      <div className="mt-3">
        8.5. Where multiple Customers share a tenancy at a Property and one
        Customer ends their tenancy at that Property after the Initial
        Cancellation Period, it is the responsibility of all Customers
        (departing and remaining Customers) to inform Us immediately and no
        later than 30 days after ending their tenancy. It will be the departing
        Customer’s responsibility to continue paying their Fees to Us unless:
      </div>
      <div className="mt-3">
        8.5.1. The remaining Customer(s) at that Property agree to pay the
        departing Customer’s proportion of the Fees and inform Housr of their
        intention to do so; or
      </div>
      <div className="mt-3">
        8.5.2. A replacement Customer agrees to take over the tenancy at that
        Property under these Terms and those of the Order contract to continue
        paying Our Fees; or
      </div>
      <div className="mt-3">
        8.5.3. The Customer provides proof of their tenancy termination. Proof
        can include confirmation from the landlord/letting agent or a tenancy
        agreement amendment/cancellation document.
      </div>
      <div className="mt-3">
        8.5.4. In either situation, We will notify all other Customers
        continuing to pay for Services in writing of any changes of this nature.
      </div>
      <div className="mt-3">
        8.6. We reserve the right to withhold the Deposit or proportion of
        Deposit from the departing Customer until such amendments to payment are
        agreed in writing.
      </div>
      <div className="mt-3">
        8.7. If Your agreed scheduled payment fails, We reserve the right to
        continue to attempt the payment until successful or an alternative
        schedule is put in place.
      </div>
      <div className="mt-3 fw-bold">9. Third-Party Finance Providers</div>
      <div className="mt-3">
        9.1. Any Third-Party Finance Provider will be supplied with and required
        to accept these terms and any Order contract agreed with the Customer on
        whose behalf they are paying.
      </div>
      <div className="mt-3">
        9.2. The effect of Clause 9.1 is to make all terms applicable to the
        Customer apply to their Third-Party Finance Provider.
      </div>
      <div className="mt-3">
        9.3. We refer to Clauses 1.4 and subclauses 1.4.1-1.4.3 for clarity
        regarding payment liability in the event that a Third-Party Finance
        Provider fails to pay Us.
      </div>
      <div className="mt-3 fw-bold">10. Property Owner as Customer</div>
      <div className="mt-3">
        10.1. If a Customer is the Owner of the Property and is paying fees on
        behalf of Tenants, they must ensure that their Tenants comply with these
        terms and those of any Order contract under which We agree to provide
        Utilities and Services.
      </div>
      <div className="mt-3">
        10.2. If the Customer is an appointed agent of the Property’s Owner,
        they must confirm to Us in writing that they have full authority to
        enter into contracts on behalf of the Property’s Owner for Services.
      </div>
      <div className="mt-3 fw-bold">11. Our Liability To You</div>
      <div className="mt-3">
        11.1. If We fail to comply with these Terms, We are responsible for
        foreseeable loss or damage that You suffer as result of Our breach of
        the Terms. We are not responsible for any unforeseeable loss or damage.
        Losses or damages are foreseeable if they are an obvious consequence of
        Our breach or if they were contemplated by You and Us at the time We
        entered into this contract.
      </div>
      <div className="mt-3">
        11.2. If We are providing Services to Your Property, We will make good
        any damage to Your Property caused by Us in the course of installation
        or performance of Services. We are, however, not responsible for the
        cost of repairing any pre-existing faults or damage to Your Property
        that We discover in the course of installation and/or performance of
        Services by Us.
      </div>
      <div className="mt-3">
        11.3. We only supply the Services for domestic and private use. You
        agree not to use the Services for any commercial, business or re-sale
        purposes, and We have no liability to You for any loss of profit, loss
        of business, business interruption or loss of business opportunity
        howsoever or wheresoever arising.
      </div>
      <div className="mt-3">
        11.4. We do not exclude or limit in any way Our liability for:
      </div>
      <div className="mt-3">
        11.4.1. Death or personal injury caused by Our negligence or the
        negligence of Our employees, agents or subcontractors; or
      </div>
      <div className="mt-3">
        11.4.2. Fraud or fraudulent misrepresentation; or
      </div>
      <div className="mt-3">
        11.4.3. Breach of the terms implied by section 2 of the Supply of Goods
        and Services Act 1982 (title and quiet possession); or
      </div>
      <div className="mt-3">
        11.4.4. Breach of the terms implied by sections 3, 4 and 5 of the Supply
        of Goods and Services Act 1982 (description, satisfactory quality,
        fitness for purpose and samples); or
      </div>
      <div className="mt-3">
        11.4.5. Defective products under the Consumer Protection Act 1987.
      </div>
      <div className="mt-3 fw-bold">12. Events Outside of Our Control</div>
      <div className="mt-3">
        12.1. We will not be liable or responsible for any failure to perform,
        or delay in performance of, any of Our obligations under these Terms
        that is caused by an Event Outside Our Control and reserve the right to
        update our prices where these events occur.
      </div>
      <div className="mt-3">
        12.2. An Event Outside Our Control is defined as any act or circumstance
        which is beyond Our reasonable control, including without limitation,
        any act of God, the public enemy, strike, lock-out or other industrial
        action, civil commotion, public demonstration, sabotage, act of
        vandalism, blockade, riot, invasion, insurrection, terrorist attack or
        threat of terrorist attack, war declared or undeclared, or threat or
        preparation for war, fire, lightning, explosion, storm, flood, drought,
        earthquake, subsidence, epidemic or another natural disaster, an
        unforeseen increase in wholesale energy costs beyond ordinary volatility
        or failure of public or private telecommunications networks.
      </div>
      <div className="mt-3">
        12.3. If an Event Outside Our Control takes place that affects the
        performance of Our obligations under these Terms:
      </div>
      <div className="mt-3">
        12.4. We will contact You as soon as reasonably possible to notify You;
        and
      </div>
      <div className="mt-3">
        12.5. Our obligations under these Terms will be suspended and the time
        for performance of Our obligations will be extended for the duration of
        the Event Outside Our Control. Where the Event Outside Our Control
        affects Our performance of Services to you, We will restart the Services
        as soon as reasonably possible after the Event Outside Our Control is
        over.
      </div>
      <div className="mt-3">
        12.6. You may cancel the contract if an Event Outside Our Control takes
        place and You no longer wish Us to provide the Services. Please see Your
        cancellation rights under Clause 13. We will only cancel the contract if
        the Event Outside Our Control continues for longer than 30 days in
        accordance with Our cancellation rights in Clause 14.
      </div>
      <div className="mt-3 fw-bold">
        13. Your Rights to Cancel and Applicable Refund
      </div>
      <div className="mt-3">
        13.1. Before We begin to provide the Services, You have the following
        rights to cancel an Order for Services, including where You choose to
        cancel because We are affected by an Event Outside Our Control or if We
        change these Terms under Clause 4.1 to Your material disadvantage:
      </div>
      <div className="mt-3">
        13.2. You may cancel any Order for Services within 14 calendar days of
        placing an Order by contacting Us through Our Help Centre page on Our
        website. We will confirm Your cancellation in writing to You.
      </div>
      <div className="mt-3">
        13.3. If You cancel an Order under Clause 13.1 and You have made any
        payment in advance for Services that have not been provided to You, We
        will refund these amounts to You within 14 days of receiving Your
        cancellation notification.
      </div>
      <div className="mt-3">
        13.4. However, if You cancel an Order for Services under Clause 13.1 and
        We have already started work on Your Order or if We are already
        providing Services by that time, You will pay Us any costs We reasonably
        incurred in starting to fulfil the Order, and this charge will be
        deducted from any refund that is due to You or, if no refund is due to
        You, invoiced to You. We will tell You what these costs are when You
        contact Us. However, where You have cancelled an Order because of Our
        failure to comply with these Terms (except where We have been affected
        by an Event Outside Our Control), You do not have to make any payment to
        Us.
      </div>
      <div className="mt-3">
        13.5. After 14 calendar days of placing, signing and Ordering a contract
        with Us, Your Initial Cancellation Period will end.
      </div>
      <div className="mt-3">
        13.6. After the Initial Cancellation Period and once We have begun to
        provide Services to You, You may cancel the contract for the Services at
        any time by providing Us with at least 30 calendar days’ notice in
        writing. Any advance payment You have made for Services that have not
        been provided will be refunded to You.
      </div>
      <div className="mt-3">
        13.7. If You end this contract within the Minimum Term but after the
        cancellation period, there will be a £30 cancellation fee per service
        per Tenant.
      </div>
      <div className="mt-3">
        13.8. Once We have begun to provide the Services to You, You may cancel
        the contract for Services with immediate effect by giving Us written
        notice if:
      </div>
      <div className="mt-3">
        13.8.1. We break this contract in any material way and We do not correct
        or fix the situation within 30 days of You asking Us in writing; or
      </div>
      <div className="mt-3">
        13.8.2. We go into liquidation or a receiver or an administrator is
        appointed over Our assets; or
      </div>
      <div className="mt-3">
        13.8.3. We change these Terms under Clause 4.1 to Your material
        disadvantage; or 13.8.4. We are affected by an Event Outside Our
        Control.
      </div>
      <div className="mt-3">
        13.9. If after You cancel or terminate a contract under these Terms and
        those of any Order, it becomes apparent that You are in debit to Us, We
        reserve the right to withhold any Deposit paid to Us and to demand
        payment of the balance for Fees relating to Services (or a proportion of
        the Services) We provided to You. Conversely, if You are in credit after
        the cancellation or termination of an agreement to provide Services, We
        will refund the amount within 30 days.
      </div>
      <div className="mt-3">
        13.10. If every Tenant in the contract would like to move out early, We
        will need written consent from the landlord or letting agency that You
        are no longer responsible for the bills at the property. We will also
        require written confirmation from each contract holder that they would
        like to terminate the contract early.
      </div>
      <div className="mt-3 fw-bold">14. Our Rights To Cancel</div>
      <div className="mt-3">
        14.1. We may have to cancel an Order before the start date for the
        Services due to an Event Outside Our Control or the unavailability of
        key personnel or key materials without which We cannot provide the
        Services. We will promptly contact You if this happens.
      </div>
      <div className="mt-3">
        14.2. If We have to cancel an Order under Clause 14.1 and You have made
        any payment in advance for Services that have not been provided to You,
        We will refund these amounts to You.
      </div>
      <div className="mt-3">
        14.3. Where We have already started work on Your Order for Services by
        the time We have to cancel, You will pay Us any costs We reasonably
        incurred in starting to fulfil the Order, and this charge will be
        deducted from any refund that is due to You or, if no refund is due to
        You, invoiced to You. We will tell You what these costs are when You
        contact Us. However, where You have cancelled an Order because of Our
        failure to comply with these Terms (except where We have been affected
        by an Event Outside Our Control), You do not have to make any payment to
        Us.
      </div>
      <div className="mt-3">
        14.4. If we decide to cancel your Order, We will not charge You anything
        for Services you have not been supplied with.
      </div>
      <div className="mt-3">
        14.5. Once We have begun to provide the Services to You, We may cancel
        the contract for the Services at any time by providing You with at least
        30 calendar days’ notice in writing. If You have made any payment in
        advance for Services that have not been provided to You, We will refund
        these amounts to You.
      </div>
      <div className="mt-3">
        14.6. We may cancel the contract for Services at any time with immediate
        effect by giving you written notice if:
      </div>
      <div className="mt-3">
        14.6.1. You do not pay Us when You are supposed to as set out in Clause
        8.3. This does not affect Our right to charge You interest under Clause
        8.2; or
      </div>
      <div className="mt-3">
        14.6.2. You break the contract in any other material way and You do not
        correct or fix the situation within 14 days of Us asking You to do so in
        writing.
      </div>
      <div className="mt-3">
        14.7. If after We cancel or terminate a contract under these Terms and
        those of any Order, it becomes apparent that You are in debit to Us, We
        reserve the right to withhold any Deposit paid to Us and to demand
        payment of the balance for Fees relating to Services (or a proportion of
        the Services) We provided to You. Conversely, if You are in credit after
        the cancellation or termination of an agreement to provide Services, We
        will refund the amount within 30 days.
      </div>
      <div className="mt-3 fw-bold">15. Contacting Housr</div>
      <div className="mt-3">
        15.1. If You have any questions or complaints, please contact Us. You
        can do so by emailing Us at billing@housrapp.uk.com or by phone at 0161
        2585529 (Monday-Friday, 9am-5pm)
      </div>
      <div className="mt-3">
        15.2. If You wish to contact Us in writing, or if any Clause in these
        Terms requires You to give Us notice in writing (for example, to cancel
        the contract), You can send this to Us by email, by hand or by pre-paid
        post to:
      </div>
      <div className="mt-3">
        <ul style={{ listStyleType: "none" }} className="ps-0">
          <li>Housr Living Ltd,</li>
          <li>8 Lower Byrom St,</li>
          <li>Manchester,</li>
          <li>M3 4AP</li>
        </ul>
      </div>
      <div className="mt-3">
        15.3. Any such notice shall only be deemed effective once it is
        received.
      </div>
      <div className="mt-3">
        15.4. We will confirm receipt of this by contacting You in writing. If
        We have to contact You or give you notice in writing, We will do so by
        email, by hand or by pre-paid post to the address You provide to Us in
        your Order.
      </div>
      <div className="mt-3 fw-bold">
        16. Data Protection and Use of Your Information
      </div>
      <div className="mt-3">
        16.1. We will use the personal information You provide to Us to:
      </div>
      <div className="mt-3">16.1.1. Provide the Services;</div>
      <div className="mt-3">
        16.1.2. Process Your payment for such Services;
      </div>
      <div className="mt-3">
        16.2. You agree that We may pass Your personal information (or those of
        Your Third-Party Finance Provider) to credit reference agencies and that
        they may keep a record of any search that they do.
      </div>
      <div className="mt-3">
        16.3. You consent under these Terms to Us passing Your personal details
        to third parties within the UK who may be able to assist You with
        additional relevant services. This does not affect Your statutory rights
        to object to this, provided You communicate this to Us in writing when
        making an Order contract or thereafter.
      </div>
      <div className="mt-3">
        16.4. More details of Our data protection policy can be found{" "}
        <b>
          <a
            target="_blank"
            href="https://www.housrapp.co.uk/privacy-policy/"
            style={{ textDecoration: "none" }}
          >
            here.
          </a>
        </b>
      </div>
      <div className="mt-3 fw-bold">17. Token Meter Usage for Fees</div>
      <div className="mt-3">
        17.1. Certain Properties may still be using Token Meters to calculate
        Utility or Service usage. Generally, We do not cover utilities provided
        by token meters, so if Your house has a token meter, We will remove it
        from Your payment plan. In extenuating circumstances where We cover the
        use of a token meter, You will be provided with a means to make
        prepayments. You must keep the relevant payment device provided by Us
        safe. Failure to do so may result in additional costs and fees for You
        to replace the payment device.
      </div>
      <div className="mt-3">
        17.2. It is the Customer’s responsibility to ensure that they maintain
        sufficient credit on their Token Meter for their usage of Utilities and
        Services.
      </div>
      <div className="mt-3">
        17.3. At the end of Your Order contract with Us, We reserve the right to
        retain any excess credit to cover outstanding Fees You owe. Should there
        be any excess credit on Your meter account, We will refund this to You
        without request.
      </div>
      <div className="mt-3">
        17.4. You are obligated to inform Us if You have a prepayment or token
        meter within Your property as it can affect Our ability to supply Your
        services.
      </div>
      <div className="mt-3 fw-bold">18. Contract Energy Allowance</div>
      <div className="mt-3">
        18.1. We will make every effort to send notifications if You go over
        Your energy allowance and provide You with handy energy-saving tips.
        Please note that We are not liable for energy usage within properties,
        and the ultimate responsibility for energy usage lies with the Customer.
        A usage breakdown for the property is available to all Customers in
        their online account, and it is the Customer’s responsibility to monitor
        their usage appropriately.
      </div>
      <div className="mt-3">
        18.2. If Your property has multiple meters for the same service, it may
        lead to Your online usage graphs becoming inaccurate. If this is true of
        Your property, You will need to request an update from Our customer
        service team about Your energy usage.
      </div>
      <div className="mt-3">
        18.3. We may change Your monthly payments for gas and electricity if We
        notice that You are using or are likely to use a different level of
        energy than was estimated at the start of Your contract.
      </div>
      <div className="mt-3">
        18.4. We encourage You to submit regular meter readings throughout the
        year. Based on these readings, We will be better able to monitor Your
        usage and inform You whether You are under or over the allowance covered
        in Your payment plan. Failure to do this may result in missed warnings.
      </div>
      <div className="mt-3 fw-bold">19. Meter Readings</div>
      <div className="mt-3">
        19.1. The purpose of Smart Meters is for both You and Us to review the
        usage of Services and ensure accurate readings. However, even where a
        smart meter is installed, regular meter readings will still need to be
        sent to us.
      </div>
      <div className="mt-3">
        19.2. Likewise, We will collect information from Your Smart Meter and
        You agree that We can obtain and record as necessary to manage Your
        Utilities usage. We will use these readings as a basis to calculate and
        charge Fees.
      </div>
      <div className="mt-3">
        19.3. If a Property does not have a Smart Meter installed and We believe
        it is necessary, We will bear the cost of installation through use of
        Our Agents/Engineers. If there is no Smart Meter installed and the
        Customer desires this, We may add this additional cost to Your Fees,
        subject to discussion and agreement with You.
      </div>
      <div className="mt-3">
        19.4. In some cases, We are unable to receive readings from Your smart
        meter. We will do our best to contact You and request readings in this
        instance. If this is the case, You are required to submit readings to
        ensure accurate billing.
      </div>
      <div className="mt-3">
        19.5. You are obligated to supply meter readings at the start, end and
        at monthly intervals throughout Your contract. If We do not receive
        readings, We will use estimates to determine Your energy usage.
      </div>
      <div className="mt-3">
        19.6. We will do Our best to notify You when We require readings. We
        will also attempt to send field agents to collect Your readings.
      </div>
      <div className="mt-3 fw-bold">20. Deemed Contracts</div>
      <div className="mt-3">
        20.1. A Deemed Contract is a statutory contract between You and Us,
        which is deemed to exist because We supply Services to a property of
        which you are the Tenant or Owner when You have not actively agreed to
        that Service by us.
      </div>
      <div className="mt-3">
        20.2. This includes when You do not take any action to move supplier at
        the beginning of Your occupation of the Property or at the end of Your
        agreed Order with Us.
      </div>
      <div className="mt-3">
        20.3. In such instances, an Order will be deemed to be between Us and a
        Tenant or the Owner until We are contacted by another Customer.
      </div>
      <div className="mt-3">
        20.4. If Your previous contract period expires and We continue to supply
        energy to Your property, a new deemed contract will be formed between
        us. The deemed contract will be charged at the prevailing standard
        variable tariff until We agree on new contract terms.
      </div>
      <div className="mt-3">
        20.4.1. Any direct debits will not be transferred to Your new contract
        until we get further confirmation that You would like to continue to pay
        via direct debit. You will be required to pay on receipt of Your
        invoices.
      </div>
      <div className="mt-3">
        20.4.2. The deemed contract does not include bill splitting services,
        and all tenants using or consuming energy in the property will be held
        jointly liable for all energy invoices. If the property is unoccupied,
        the owner will be held liable.
      </div>
      <div className="mt-3 fw-bold">21. Final Statements</div>
      <div className="mt-3">
        21.1. Upon completion of the tenancy, You are entitled to request a
        final statement outlining Your property’s total usage for the year and
        reconciling this with payments that You have made. We will make every
        effort to complete this process within three (3) months of the request;
        however, We reserve the right to extend this time in a manner We deem
        appropriate in relation to Our business.
      </div>
      <div className="mt-3">This includes but is not limited to:</div>
      <div className="mt-3">
        21.1.1. The late receipt of bills from suppliers;
      </div>
      <div className="mt-3">21.1.2. Missing or disputed meter readings;</div>
      <div className="mt-3">
        21.1.3. Intervention from a third-party representative/ombudsman;
      </div>
      <div className="mt-3">
        21.2. All customers will receive a final statement but requests will be
        prioritised.
      </div>
      <div className="mt-3">
        21.3. Unlimited Plan Customers will receive a final statement which will
        reconcile their monthly payments against the contracted payment total.
      </div>
      <div className="mt-3">
        21.3.1. Statements may include cost for missing equipment, late payment
        fees or missed appointment fees.
      </div>
      <div className="mt-3">
        21.4. Any amount requested on the final statement will be automatically
        scheduled on Your account using Your payment method after the statement
        has been issued.
      </div>
      <div className="mt-3">
        21.4.1. If You would like to request a payment plan, We will require i)
        one proof of forwarding address (e.g. a utility bill or bank statement)
        and ii) one copy of photo identification (e.g. a driving licence or
        passport).
      </div>
      <div className="mt-3 fw-bold">22. Complaints</div>
      <div className="mt-3">
        22.1. As part of Our commitment to providing high-quality customer
        service, We endeavour to deal with any complaints submitted to Us in
        writing in a fair and expedient manner.
      </div>
      <div className="mt-3">
        22.2. You can raise a complaint via email, phone, letter or by
        completing the complaints form on Our website. We will aim to respond to
        Your complaint within five (5) Working Days.
      </div>
      <div className="mt-3">
        22.3. We will record Your complaint and any resolutions We have reached
        on Your account. If We need any further information from You, We will be
        in touch within five (5) Working Days to request this.
      </div>
      <div className="mt-3">
        22.4. Once We have initially responded to Your complaint, You are free
        to contact Us about any questions or if You are unhappy with Our
        response.
      </div>
      <div className="mt-3">
        22.5. If We do not hear back from You within seven (7) Working Days
        following Our proposed remedy, We will follow up with You. If We haven’t
        resolved the complaint to Your satisfaction after eight (8) weeks, We
        will write to You again. You will then be able to escalate Your
        complaint to the Ombudsman Services: Energy if You wish. If You have any
        questions about this, please do not hesitate to get in touch.
      </div>
      <div className="mt-3">
        22.6. We will provide You with one or more of the following outcomes:
      </div>
      <div className="mt-1 ps-3">
        22.6.1. An explanation of Your account and what has happened
      </div>
      <div className="mt-1 ps-3">
        22.6.2. An apology for any errors on Our side
      </div>
      <div className="mt-1 ps-3">
        22.6.3. Compensation applied to Your account
      </div>
      <div className="mt-1 ps-3">
        22.6.4. Remedial action of any errors on Your account.
      </div>
      <div className="mt-3">
        22.7. You may feel You need extra support with Your complaint, so You
        can contact Citizens Advice Consumer Service (CACS) or the Extra Help
        unit (EHU) for independent, impartial and free advice. If You are a
        domestic customer, You can contact CACS/EHU at any point of the
        complaints process.
      </div>
      <div className="mt-3">
        Citizens Advice Consumer Service contact details:
      </div>
      <div className="mt-3">Phone: 0808 223 1133</div>
      <div className="mt-3">Website: www.citizensadvice.org.uk/energy</div>
      <div className="mt-3">
        22.8. If after eight (8) weeks from the start date of Your complaint,
        You are unhappy with our resolution, You may escalate Your complaint to
        the Ombudsman Services: Energy.
      </div>
      <div className="mt-3">
        22.9. Alternatively, if We have told You there is no further action We
        can take on Your account, We will issue You a deadlock letter and You
        can take this to the Ombudsman Services: Energy.
      </div>
      <div className="mt-3">
        22.10. The Ombudsman Services: Energy is a free and impartial service
        who aid in the impartial resolution of customer complaints.
      </div>
      <div className="mt-3">Ombudsman Services contact details:</div>
      <div className="mt-3">Phone: 0330 440 1624</div>
      <div className="mt-3">Post:</div>
      <div className="mt-3">Ombudsman Services: Energy</div>
      <div className="mt-3">PO Box 966</div>
      <div className="mt-3">Warrington</div>
      <div className="mt-3">WA4 9DF</div>
      <div className="mt-3">Email: osenquiries@os-energy.org</div>
      <div className="mt-3">Website: www.ombudsman-services.org</div>
      <div className="mt-3 fw-bold">23. Additional Important Terms</div>
      <div className="mt-3">
        23.1. We may transfer Our rights and obligations under these Terms to
        another organisation, and We will always notify You in writing if this
        happens, but this will not affect Your rights or Our obligations under
        these Terms.
      </div>
      <div className="mt-3">
        23.2. You may only transfer Your rights or Your obligations under these
        Terms to another person if We agree in writing.
      </div>
      <div className="mt-3">
        23.3. This contract is between You and Us. No other person shall have
        any rights to enforce any of its terms under the Contract (Rights of
        Third Parties) Act 1999.
      </div>
      <div className="mt-3">
        23.4. When You take Our water service, You agree that You authorise Us
        to act on Your behalf with the water and sewerage suppliers from the
        requested start date until either the service or these terms end.
      </div>
      <div className="mt-3">
        23.5. We will register Your property with the relevant water and
        sewerage suppliers and pay for Your water and sewerage at Your property
        from the requested start date until either the service or these terms
        end.
      </div>
      <div className="mt-3">
        23.6. The owner of the property is responsible for any supply or quality
        issues at Your property, including repairing and maintaining Your part
        of the water service piping.
      </div>
      <div className="mt-3">
        23.7. You agree to comply with the standard terms and conditions of the
        water and sewerage supplier(s) that supply Your property with water and
        sewerage. If You have any queries, please contact Us at any time.
      </div>
      <div className="mt-3">
        23.8. Each of the paragraphs and clauses of these Terms operates
        separately. If any court or relevant authority decides that any of them
        are unlawful, the remaining paragraphs and clauses will remain in full
        force and effect.
      </div>
      <div className="mt-3">
        23.9. If We fail to insist that You perform any of Your obligations
        under these Terms, or if We do not enforce Our rights against You, or if
        We delay in doing so, that will not mean that We have waived Our rights
        against You and will not mean that You do not have to comply with those
        obligations. If We do waive a default by You, We will only do so in
        writing, and that will not mean that We will automatically waive any
        later default by You.
      </div>
      <div className="mt-3">
        23.10. These Terms and the terms of any Order contract are governed by
        the laws of England and Wales. Both Parties to this Agreement agree to
        submit to the non-exclusive jurisdiction of the England & Wales courts.
        However, if You are a resident of Northern Ireland, You may also bring
        proceedings in Northern Ireland, and if You are a resident of Scotland,
        You may also bring proceedings in Scotland.
      </div>
      <div className="mt-3">
        23.11. To prove that a tenant is liable for billing over a period of
        time, a Tenancy Agreement alone will not suffice. We need the full
        tenant name, Date of Birth, Mobile phone number, email address and
        alternative physical address. Should this information not be provided,
        We will hold the landlord responsible for the duration of the period.
      </div>
      <div className="mt-3 fw-bold">24. Energy Tariffs</div>
      <div className="mt-3">
        24.1. These tariffs are variable and valid for contracts starting on or
        after 10th February 2024. If there is a change in tariff within the
        following 12 months, the customer will be given 30 days’ notice.
      </div>
      <div className="mt-3">DOMESTIC GAS TARIFF (pence)</div>
      <div className="mt-3">
        24.2. This tariff applies to customers joining Housr via Our website.
      </div>
      <div className="mt-3">DOMESTIC ELECTRICITY TARIFFS (pence)</div>
      <div className="mt-3">
        24.3. This tariff applies to customers joining Housr via Our website.
      </div>
      <div className="mt-3">
        <Table striped>
          <thead>
            <tr>
              <th>No. Tenants</th>
              <th>Electricity (kWh)</th>
              <th>Gas (kWh)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>2100</td>
              <td>10400</td>
            </tr>
            <tr>
              <td>2</td>
              <td>2300</td>
              <td>11960</td>
            </tr>
            <tr>
              <td>3</td>
              <td>2700</td>
              <td>14950</td>
            </tr>
            <tr>
              <td>4</td>
              <td>3200</td>
              <td>18200</td>
            </tr>
            <tr>
              <td>5</td>
              <td>4200</td>
              <td>20150</td>
            </tr>
            <tr>
              <td>6</td>
              <td>5181</td>
              <td>23441.6</td>
            </tr>
            <tr>
              <td>7</td>
              <td>6186</td>
              <td>25664.6</td>
            </tr>
            <tr>
              <td>8</td>
              <td>7228</td>
              <td>28720.9</td>
            </tr>
            <tr>
              <td>9</td>
              <td>8052</td>
              <td>32522.1</td>
            </tr>
            <tr>
              <td>10</td>
              <td>8861</td>
              <td>34030.1</td>
            </tr>
            <tr>
              <td>11</td>
              <td>9852</td>
              <td>37590.8</td>
            </tr>
            <tr>
              <td>12</td>
              <td>10832</td>
              <td>41152.8</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="mt-3 fw-bold">
        25. Unreasonable Usage Terms for Unlimited Contract
      </div>
      <div className="mt-3">
        25.1. The purpose of this policy is to ensure the utilities at the
        property are not used for the following purposes:
      </div>
      <div className="mt-1 ps-3">25.1.1. Non-Domestic</div>
      <div className="mt-1 ps-3">25.1.2. Commercial</div>
      <div className="mt-1 ps-3">24.1.3. Industrial</div>
      <div className="mt-1 ps-3">25.1.4. Re-Selling Purposes</div>
      <div className="mt-3">
        25.2. If in Our opinion You are using Your utilities for any of these
        reasons or that the Usage is irresponsible and/or negligent, We may ask
        You to moderate Your Usage.
      </div>
      <div className="mt-3">
        25.3. Customers signing up for an unlimited package are obliged to sign
        up using the correct number of bedrooms in the property, regardless of
        the number of tenants within that property.
      </div>
      <div className="mt-3">
        25.4. If, upon investigation, Housr finds a customer has signed up using
        an incorrect number of bedrooms for a property, Housr reserves the right
        to backdate additional charges to the start of the contract to correct
        underpayment.
      </div>
      <div className="mt-3">Electricity & Gas</div>
      <div className="mt-3">
        25.5. Our Unreasonable Usage figure has been determined using the Ofgem
        Typical Domestic Consumption Values, published April 1st, 2020.
      </div>
      <div className="mt-3">
        25.6. Typical Domestic Consumption Values (TDCVs) are industry standard
        values for the annual gas and electricity usage of a typical domestic
        consumer.
      </div>
      <div className="mt-3">Water</div>
      <div className="mt-3">
        25.7. Your water allowance is a monetary value. This has been calculated
        using the average metered water usage per person, as calculated by the
        Consumer Council for Water. The Consumer Council for Water is a
        non-departmental public body whose sponsor department is Defra. CCWater
        is independent of both the regulator, Ofwat, and the water companies.
      </div>
      <div className="mt-3">
        25.8. This Clause only applies to properties with a water meter (about
        50% of the UK housing stock). Rateable values are fixed and therefore
        any charges cannot be classed as over-usage. For more information on the
        difference between meters and rateable values, please see our FAQs.
      </div>
      <div className="mt-3">
        25.9. Water charges vary massively around the country, but We will only
        issue additional charges if Your water bill is >25% higher than the
        Housr payment plan charges (per annum).
      </div>
      <div className="mt-3">
        25.10. Unlimited package customers will be warned when they exceed the
        unreasonable usage policy and will be contacted to discuss their payment
        plan options to avoid paying for additional usage at the end of their
        contract.
      </div>
      <div className="mt-3">
        25.11. Any usage exceeding the Unreasonable Usage Policy laid out above
        will be charged to You at the end of Your tenancy. For energy, this will
        be charged at the location-specific energy price cap level of the time.
        For water, an additional monetary value will be added.
      </div>
      <div className="mt-3 fw-bold">26. Third-party Suppliers</div>
      <div className="mt-3">
        It may be necessary for Us to pay third-party suppliers on Your behalf
        to ensure full coverage of utility services during Your contract period.
        If We need to pay third-party suppliers on Your behalf, these are the
        terms which apply:
      </div>
      <div className="mt-3">
        26.1. We will cover the cost of any third-party supplier invoice from
        Your contract start date up until the date We started supplying Your
        energy.
      </div>
      <div className="mt-3">
        26.2. You are required to send via email/post any and all third-party
        invoices to Us.
      </div>
      <div className="mt-3">
        26.2.1. If We do not receive a full copy of the third-party invoice, We
        may charge You the exact amount of the invoice split between any members
        of the contract.
      </div>
      <div className="mt-3">
        26.2.2. We do not accept any late payment fees which have accrued in
        periods in which We have not received the invoices.
      </div>
      <div className="mt-3">
        26.3. We will only cover the cost of gas and electricity within Your
        contract dates.
      </div>
      <div className="mt-3">
        26.4. If the third-party invoice requested payment far in excess of
        payments made to Us, We may decide to either: not pay the invoice,
        request further payment from You or apply the charge in full to Your
        final statement before We make the payment on Your behalf.
      </div>
    </body>
  );
};
