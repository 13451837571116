import "./css/AllBlogs.css";
import { useEffect, useState } from "react";
import { getAllBlogs } from "../api";
import { useNavigate } from "react-router-dom";
import background_stripe from "../Icons/background_stripe.png";
import { Spinner } from "react-bootstrap";

const AllBlogs = () => {
    const [allBlogs, setAllBlogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        getAllBlogs().then((res) => {
            setAllBlogs(res);
            setLoading(false);
        });
    }, []);

    const buildPreviewText = (input) => {
        return input.replace(/(<([^>]+)>)/gi, "");
    };

    const buildBlogEntries = () => {
        return allBlogs.map((blog) => {
            return (
                <div
                    key={blog.id}
                    className="blog"
                    onClick={() => navigate(`/blogs/${blog.id}`)}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: ".5em",
                        }}
                    >
                        <img
                            src={JSON.parse(blog.images)[0]}
                            style={{
                                aspectRatio: 1,
                                width: "20%",
                                marginRight: 24,
                                borderRadius: 12,
                                objectFit: "cover",
                            }}
                        />
                        <div>
                            <div className="blog-title">{blog.title}</div>
                            <div className="blog-preview">
                                {buildPreviewText(blog.body)}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                height: "100vh",
            }}
        >
            <img
                src={background_stripe}
                className="background-stripe"
                alt="background stripe"
            />
            <div className="blogs-page">
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                        <Spinner />
                    </div>
                ) : (
                    <>
                        <div className="page-title">All Blogs</div>
                        <div className="blog-list">{buildBlogEntries()}</div>
                    </>
                )}
            </div>
        </div>
    );
};

export default AllBlogs;
