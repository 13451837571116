import faqs from "../json/faqs.json";
import "./css/Faqs.css";

export const Faqs = () => {
  return (
    <div
    className="mx-auto mt-4 py-4 w-100 info-tile-sign-up"
      style={{
        borderRadius: "1rem",
        boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
        backgroundColor: "white",
        marginBottom: "10%",
      }}
    >
      <div>
        <h5 className="fw-bold" style={{ color: "#109539" }}>
          FAQs
        </h5>
      </div>
      <div
        className="accordion mx-auto"
        id="accordionFaqs"
        style={{ width: "90%" }}
      >
        {faqs.map((faq, index) => {
          return (
            <div className="accordion-item" key={index}>
              <h2 className="accordion-header" id={`heading${index}`}>
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#faq${index}`}
                  aria-expanded="false"
                  aria-controls={`faq${index}`}
                  style={{ color: "white" }}
                >
                  {faq.title}
                </button>
              </h2>

              <div
                id={`faq${index}`}
                className="accordion-collapse collapse"
                aria-labelledby={`heading${index}`}
                data-bs-parent="#accordionFaqs"
              >
                <div
                  className="accordion-body fw-bold"
                  style={{ fontSize: 14 }}
                >
                  {faq.body}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
