import axios from "axios";
import React, { useEffect, useState } from "react";
import {
    Alert,
    Button,
    Form,
    FormCheck,
    InputGroup,
    Modal,
} from "react-bootstrap";
import GooglePlacesAutocomplete, {
    geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { useLocation, useSearchParams } from "react-router-dom";
import housr_logo from "../Icons/housr_nova_icon.svg";
import edit_icon from "../Icons/edit.png";
import PhoneInput from "react-phone-number-input";
import ConfettiExplosion from "react-confetti-explosion";
import "./css/Tenants.css";
import { TermsAndConditions } from "./TermsAndConditions";

export const Tenants = () => {
    const [address, setAddress] = useState({
        firstAddress: "",
        secondAddress: "",
        city: "",
        postcode: "",
    });
    const [tenancyStart, setTenancyStart] = useState(
        new Date().getFullYear() + "-07-01"
    );
    const [tenancyEnd, setTenancyEnd] = useState("");
    const [changeTenancyEnd, setChangeTenancyEnd] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertHeading, setAlertHeading] = useState("");
    const [alertBody, setAlertBody] = useState("");
    const [referralCode, setReferralCode] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [searchAddress, setSearchAddress] = useState(null);
    const [currentModalIndex, setCurrentModalIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [displayedFlatmateData, setDisplayedFlatmateData] = useState([]);
    const [isExploding, setIsExploding] = useState(false);
    const [flatmateData, setFlatmateData] = useState([
        {
            firstName: "",
            lastName: "",
            number: "",
        },
    ]);
    const [tenants, setTenants] = useState(1);
    const [showTC, setShowTC] = useState(false);
    const [leadTenantEmail, setLeadTenantEmail] = useState("");

    const [searchParams] = useSearchParams();
    const referral = searchParams.get("referral");

    let { state } = useLocation();

    const leadTenant = {
        leadTenantId: 0,
        leadTenantNumber: "",
        leadTenantName: "",
    };
    const discountedPrice = state?.discountedPrice;
    const discountCode = state?.discountCode;
    const hubSpotContactIds = state?.hubSpotContactIds;

    useEffect(() => {
        setAddress({});

        if (!submitted) {
            setReferralCode(referral);
        }

        if (tenancyEnd === "") {
            addOneYear(tenancyStart);
        }

        setAddress((prevAddress) => {
            let updatedAddress = {};

            if (searchAddress?.value?.place_id) {
                geocodeByPlaceId(searchAddress?.value?.place_id)
                    .then((results) => {
                        const addressComponents = results[0].address_components;
                        setAddress((prevAddress) => ({
                            ...prevAddress,
                            postcode:
                                addressComponents[addressComponents.length - 1]
                                    .long_name,
                        }));
                    })
                    .catch((error) => console.error(error));
            }

            const formattedSearchAddress = searchAddress?.label.split(",");
            if (formattedSearchAddress?.length > 0) {
                updatedAddress = {
                    ...prevAddress,
                    firstAddress: formattedSearchAddress[0],
                };

                if (formattedSearchAddress?.length > 2) {
                    updatedAddress.secondAddress =
                        formattedSearchAddress[1].trimStart();
                }

                updatedAddress.city =
                    formattedSearchAddress[
                        formattedSearchAddress.length - 1
                    ].trimStart();
            }

            return updatedAddress;
        });
    }, [referral, searchAddress, tenancyStart, tenancyEnd, submitted]);

    const numOfTenants = [];

    if (tenants > 0) {
        for (let i = 0; i < tenants; i++) {
            numOfTenants.push(i);
        }
    }

    const submitForm = () => {
        setShowAlert(false);
        window.scroll({ top: 0, behavior: "smooth" });
        if (!address.firstAddress) {
            setAlertHeading("Error with your address");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your address has not been completely filled in yet!"
            );
            setShowAlert(true);
        } else if (!address.city) {
            setAlertHeading("Error with your city");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your city has not been chosen!"
            );
            setShowAlert(true);
        } else if (!address.postcode) {
            setAlertHeading("Error with your postcode");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your postcode has not been completely filled in yet!"
            );
            setShowAlert(true);
        } else if (!tenancyStart) {
            setAlertHeading("Error with your tenancy start date");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your tenancy start date has not been filled in yet!"
            );
            setShowAlert(true);
        } else if (changeTenancyEnd && !tenancyEnd) {
            setAlertHeading("Error with your tenancy end date");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected your tenancy end date has not been filled in yet!"
            );
            setShowAlert(true);
        } else if (
            displayedFlatmateData.length < tenants - 1 ||
            displayedFlatmateData.some(
                (flatmate) =>
                    !flatmate.firstName ||
                    !flatmate.lastName ||
                    !flatmate.number
            )
        ) {
            setAlertHeading("Error with your tenant details");
            setAlertBody(
                "Please ensure that you have filled in all of the sections of this form. We have detected one or more of your tenant details has not been filled in yet!"
            );
            setShowAlert(true);
        } else if (
            displayedFlatmateData.some(
                (flatmate) => !isPhoneNumberValid(flatmate.number)
            )
        ) {
            setAlertHeading("Invalid Phone Number");
            setAlertBody(
                "One or more of the phone numbers you have entered is invalid. Please ensure you have entered the correct phone number for all of your flatmates."
            );
            setShowAlert(true);
        } else {
            postRequest();
        }
    };

    const isPhoneNumberValid = (number) => {
        return !number.startsWith("+44") || number.length === 13;
    };

    const postRequest = () => {
        setSubmitting(true);
        axios
            .post("/house_tenants", {
                address,
                bedrooms: tenants,
                tenancyStart,
                tenancyEnd,
                displayedFlatmateData: displayedFlatmateData.slice(1),
                referralCode,
                leadTenant: {
                    leadTenantId: leadTenant.leadTenantId,
                    leadTenantEmail,
                    leadTenantNumber: displayedFlatmateData[0].number,
                    leadTenantName: `${displayedFlatmateData[0].firstName} ${displayedFlatmateData[0].lastName}`,
                },
                package: "unlimited",
                discountedPrice,
                discountCode,
                leadTenantFlatmates: [],
                hubSpotContactIds,
            })
            .then(() => {
                setIsExploding(true);
                resetForm();
            })
            .catch((err) => {
                console.error(err);
                setSubmitting(false);
                setSubmitted(false);
                if (err.response?.data) {
                    setAlertHeading("Error with your request");
                    setAlertBody(err.response?.data);
                } else {
                    setAlertHeading("Error with your request");
                    setAlertBody("Something went wrong, please try again");
                }
                setShowAlert(true);
            });
    };

    const addOneYear = (startDate) => {
        let date = new Date(startDate);

        date.setFullYear(date.getFullYear() + 1);
        date.setDate(date.getDate() - 1);

        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, "0");
        let day = String(date.getDate()).padStart(2, "0");

        let endDate = `${year}-${month}-${day}`;
        setTenancyEnd(endDate);
    };

    const resetForm = () => {
        setSubmitting(false);
        setSubmitted(true);
        setAddress({});
        setTenancyStart("");
        setTenancyEnd("");
        setReferralCode("");
        setSearchAddress(null);
        setChangeTenancyEnd(false);
        setFlatmateData([{}]);
    };

    const AlertValidation = () => {
        return (
            <Alert
                show={showAlert}
                variant="danger"
                onClose={() => setShowAlert(false)}
                dismissible
                style={{
                    zIndex: 10,
                    width: "100%",
                }}
            >
                <Alert.Heading>{alertHeading}</Alert.Heading>
                <p>{alertBody}</p>
            </Alert>
        );
    };

    const FormSubmitted = () => {
        return (
            <div className="bg-success text-light p-3 mt-2 success-popup">
                <h5>Form Submitted</h5>
                <p>
                    You should receive a SMS message with a link to the
                    utilities form for you to sign.
                </p>
            </div>
        );
    };

    const handleModalStart = (num) => {
        setCurrentModalIndex(num);
        setShowModal(true);
    };

    const handleModalClose = () => {
        if (currentModalIndex < tenants) {
            setCurrentModalIndex(currentModalIndex + 1);
        }
    };

    const handleFlatmateChange = (e, type) => {
        let value;
        if (type === "number") {
            value = e;
        } else {
            value = e.target.value;
            if (type === "email") {
                value = value.toLowerCase();
                setLeadTenantEmail(value);
            }
        }
        setDisplayedFlatmateData((flatmateData) => {
            const newData = [...flatmateData];
            newData[currentModalIndex] = {
                ...newData[currentModalIndex],
                [type]: value,
            };
            return newData;
        });
    };

    const handleModalFinalClose = () => {
        setShowModal(false);
        setCurrentModalIndex(tenants + 1);
    };

    const TermsConditionsModal = () => {
        return (
            <Modal
                show={showTC}
                onHide={() => {
                    setShowTC(false);
                }}
                className="d-flex flex-column"
            >
                <Modal.Header closeButton className="fw-bold">
                    Terms & Conditions
                </Modal.Header>
                <Modal.Body>
                    <TermsAndConditions />
                </Modal.Body>
            </Modal>
        );
    };

    return tenants ? (
        <div className="py-3 py-md-5">
            <TermsConditionsModal />
            <Form className="tenant-details-signup-form mx-auto">
                <div className="housr-logo">
                    <img src={housr_logo} alt="logo" />
                </div>
                <h1 className="signup-header text-center ps-0">
                    Let's get you on board!
                </h1>
                <AlertValidation />
                {submitted && <FormSubmitted />}
                <div>
                    <p className="input-title">Your Address</p>
                    <InputGroup>
                        <InputGroup.Text className="input-g-title">
                            Search
                        </InputGroup.Text>
                        <div
                            style={{
                                width: "80%",
                            }}
                        >
                            <GooglePlacesAutocomplete
                                apiOptions={{
                                    language: "en",
                                    region: "uk",
                                }}
                                apiKey={
                                    "AIzaSyAx9PXk8lKpp3o19zYBOiNX1UB5YroEBLQ"
                                }
                                selectProps={{
                                    placeholder:
                                        "Start typing your full address",
                                    value: searchAddress,
                                    onChange: setSearchAddress,
                                }}
                            />
                        </div>
                    </InputGroup>
                    <Form.Group className="mt-3">
                        <InputGroup>
                            <Form.Control
                                id="firstAddress"
                                type="address"
                                name="firstAddress"
                                value={address.firstAddress}
                                placeholder="Address Line One"
                                onChange={(e) => {
                                    setAddress({
                                        ...address,
                                        firstAddress: e.target.value,
                                    });
                                }}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mt-1">
                        <InputGroup>
                            <Form.Control
                                id="secondAddress"
                                type="address"
                                name="secondAddress"
                                value={address.secondAddress}
                                placeholder="Address Line Two"
                                onChange={(e) => {
                                    setAddress({
                                        ...address,
                                        secondAddress: e.target.value,
                                    });
                                }}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="d-flex mt-1">
                        <InputGroup>
                            <Form.Control
                                id="city"
                                type="text"
                                name="city"
                                value={address.city}
                                placeholder="City"
                                onChange={(e) => {
                                    setAddress({
                                        ...address,
                                        city: e.target.value,
                                    });
                                }}
                            />
                        </InputGroup>
                        <InputGroup>
                            <Form.Control
                                id="postcode"
                                type="text"
                                name="postcode"
                                value={address.postcode}
                                placeholder="Postcode"
                                onChange={(e) => {
                                    setAddress({
                                        ...address,
                                        postcode: e.target.value,
                                    });
                                }}
                            />
                        </InputGroup>
                    </Form.Group>
                    <div className="d-flex pt-2 justify-content-between ">
                        <p className="input-title">Tenancy Start Date</p>
                        {changeTenancyEnd && (
                            <p className="input-title justify-content-start d-flex w-50">
                                Tenancy End Date
                            </p>
                        )}
                    </div>
                    <Form.Group className="d-flex">
                        <InputGroup className="w-50">
                            <Form.Control
                                id="tenancyStartDate"
                                type="date"
                                min={new Date().toISOString().split("T")[0]}
                                name="tenancyStartDate"
                                value={tenancyStart}
                                onChange={(e) => {
                                    setTenancyStart(e.target.value);
                                    addOneYear(e.target.value);
                                }}
                            />
                        </InputGroup>
                        {changeTenancyEnd && (
                            <InputGroup className="ms-2 w-50">
                                <Form.Control
                                    id="tenancyEndDate"
                                    type="date"
                                    name="tenancyEndDate"
                                    value={tenancyEnd}
                                    min={tenancyStart}
                                    onChange={(e) => {
                                        setTenancyEnd(e.target.value);
                                    }}
                                />
                            </InputGroup>
                        )}
                    </Form.Group>

                    <Form.Group
                        className="d-flex flex-row mt-2"
                        controlId="exampleForm.ControlInput1"
                    >
                        <FormCheck
                            className="me-3"
                            type={"checkbox"}
                            checked={changeTenancyEnd}
                            onChange={() => {
                                setTenancyEnd("");
                                setChangeTenancyEnd(!changeTenancyEnd);
                            }}
                        />
                        <Form.Label className="text-start">
                            Click this if you don't have a 12-month contract.
                        </Form.Label>
                    </Form.Group>
                    <Form.Group>
                        <p className="input-title">Referral Code</p>
                        <InputGroup>
                            <Form.Control
                                value={referralCode}
                                disabled={referral ?? false}
                                onChange={(e) => {
                                    setReferralCode(e.target.value);
                                }}
                            />
                        </InputGroup>
                    </Form.Group>
                </div>

                <div className="mt-2">
                    <p className="input-title">Tenants Information</p>
                    <div className="mb-3 housemates-section">
                        {numOfTenants.map((numOfTenant, index) => {
                            return (
                                <div className="mb-1" key={numOfTenant}>
                                    <div className="d-flex flex-column">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex justify-content-start ps-1 fw-bold">
                                                {displayedFlatmateData[
                                                    numOfTenant
                                                ]?.firstName
                                                    ? displayedFlatmateData[
                                                          numOfTenant
                                                      ]?.firstName +
                                                      " " +
                                                      (displayedFlatmateData[
                                                          numOfTenant
                                                      ]?.lastName || "")
                                                    : index === 0
                                                    ? ""
                                                    : "Tenant " + numOfTenant}
                                                {index === 0
                                                    ? " (Lead tenant)"
                                                    : ""}
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                {
                                                    displayedFlatmateData[
                                                        numOfTenant
                                                    ]?.number
                                                }
                                                <a
                                                    className="ms-1"
                                                    onClick={() =>
                                                        handleModalStart(
                                                            numOfTenant
                                                        )
                                                    }
                                                >
                                                    <img
                                                        src={edit_icon}
                                                        alt="edit"
                                                    />
                                                </a>
                                                {index !== 0 && (
                                                    <div
                                                        onClick={() => {
                                                            setDisplayedFlatmateData(
                                                                (
                                                                    currentFlatmateData
                                                                ) => {
                                                                    const newData =
                                                                        [
                                                                            ...currentFlatmateData,
                                                                        ];
                                                                    newData.splice(
                                                                        numOfTenant,
                                                                        1
                                                                    );
                                                                    return newData;
                                                                }
                                                            );
                                                            setTenants(
                                                                (prev) =>
                                                                    prev - 1
                                                            );
                                                        }}
                                                        className="ms-2 fs-2"
                                                    >
                                                        &times;
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                borderBottom: "1px solid black",
                                                margin: "5px 0",
                                                width: "100%",
                                                borderColor: "grey",
                                                opacity: 0.5,
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            );
                        })}
                        <Button
                            hidden={submitted}
                            style={{
                                textDecoration: "underline",
                                backgroundColor: "transparent",
                                color: "var(--main-colour)",
                                border: "none",
                                fontWeight: "bold",
                            }}
                            onClick={() => setTenants((prev) => prev + 1)}
                        >
                            Add Tenant
                        </Button>
                    </div>
                </div>
                <Form.Group className="pb-3">
                    <Button
                        onClick={() => {
                            submitForm();
                        }}
                        disabled={submitting}
                        hidden={submitted}
                        className="housr-button w-100"
                        style={{ top: "1vh", position: "relative" }}
                    >
                        Sign Up
                    </Button>
                    <div className="mt-3 w-75 mx-auto">
                        <span className="text-start">
                            By signing up, you agree to the{" "}
                            <a
                                style={{
                                    color: "#1a4862",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    fontWeight: "bold",
                                }}
                                onClick={() => {
                                    setShowTC(true);
                                }}
                            >
                                Terms & Conditions.
                            </a>
                        </span>
                    </div>
                    <Button
                        onClick={() => {
                            window.location.href = "https://www.housrapp.co.uk";
                        }}
                        hidden={!submitted}
                        className="housr-button w-100 mt-3"
                    >
                        Go Home
                    </Button>
                </Form.Group>
            </Form>
            <Modal
                animation={false}
                show={showModal & (currentModalIndex < tenants)}
                onHide={handleModalFinalClose}
                centered
                fullscreen={"sm-down"}
                style={{ borderRadius: "20px" }}
            >
                <Modal.Header className="justify-content-center">
                    <Modal.Title c style={{ justifyContent: "center" }}>
                        Add{" "}
                        {currentModalIndex === 0
                            ? "Lead Tenant"
                            : `Tenant ${currentModalIndex}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-card sm-modal-tenant">
                    <Form.Group>
                        <p className="input-title">First Name</p>
                        <Form.Control
                            aria-label="First name"
                            value={
                                displayedFlatmateData[currentModalIndex]
                                    ?.firstName ?? ""
                            }
                            onChange={(e) => {
                                handleFlatmateChange(e, "firstName");
                            }}
                        />
                    </Form.Group>
                    <p className="input-title">Surname</p>
                    <Form.Control
                        aria-label="Last name"
                        value={
                            displayedFlatmateData[currentModalIndex]
                                ?.lastName ?? ""
                        }
                        onChange={(e) => {
                            handleFlatmateChange(e, "lastName");
                        }}
                    />
                    {currentModalIndex === 0 && (
                        <>
                            <p className="input-title">Email</p>
                            <Form.Control
                                aria-label="Email"
                                value={leadTenantEmail ?? ""}
                                onChange={(e) => {
                                    setLeadTenantEmail(e.target.value);
                                }}
                            />
                        </>
                    )}
                    <Form.Group>
                        <p className="input-title">Phone Number</p>
                        <PhoneInput
                            placeholder="Enter phone number"
                            withCountryCallingCode
                            defaultCountry={"GB"}
                            value={
                                displayedFlatmateData[currentModalIndex]?.number
                            }
                            onChange={(e) => {
                                handleFlatmateChange(e, "number");
                            }}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="justify-content-center d-flex">
                    {currentModalIndex === tenants - 1 ? (
                        <Button
                            className="housr-button m-2"
                            style={{ width: "90%" }}
                            onClick={handleModalFinalClose}
                        >
                            Finish
                        </Button>
                    ) : (
                        <Button
                            className="housr-button m-2"
                            style={{ width: "90%" }}
                            onClick={handleModalClose}
                        >
                            Next
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
            {isExploding && (
                <ConfettiExplosion
                    style={{ position: "absolute", left: "50%", top: "30%" }}
                    zIndex={99}
                    force={0.8}
                    duration={4000}
                    particleCount={250}
                    width={1600}
                />
            )}
        </div>
    ) : (
        <div className="mt-5">
            <h4>
                Error 404: Something went wrong - please start the form again.
            </h4>
        </div>
    );
};
