import axios from "axios";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Wallet from "./Components/Wallet";
import { Dashboard } from "./Components/Dashboard";
import { Home } from "./Components/Home";
import { LeadTenant } from "./Components/LeadTenant";
import { Leaderboard } from "./Components/Leaderboard";
import PrivateRoute from "./Components/PrivateRoute";
import { QuoteDisplay } from "./Components/QuoteDisplay";
import { QuoteRetrieval } from "./Components/QuoteRetrieval";
import ReferralsSignUp from "./Components/ReferralsSignUp";
import { Tenants } from "./Components/Tenants";
import AuthProvider from "./Providers/AuthProvider";
import { Profile } from "./Components/Profile";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { useEffect } from "react";
import AllBlogs from "./Components/AllBlogs";
import Blog from "./Components/Blog";

ReactGA.initialize("G-MHSVS7VXZP");
const tagManagerArgs = {
    gtmId: "GTM-PP6ZVM6G",
};
TagManager.initialize(tagManagerArgs);

function App() {
    const location = useLocation();

    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json";

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: location.pathname,
            title: location.pathname,
        });

        window.dataLayer.push({
            event: "pageview",
            page: {
                url: location.pathname,
                title: location.pathname,
            },
        });
    }, [location]);

    return (
        <div className="App">
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<Tenants />}></Route>
                    <Route
                        path="/signup/bill-quotes/:bedrooms"
                        element={<QuoteDisplay />}
                    ></Route>
                    <Route
                        path="/signup/bill-quotes"
                        element={<QuoteRetrieval />}
                    ></Route>
                    <Route path="/blogs" element={<AllBlogs />}></Route>
                    <Route path="/blogs/:id" element={<Blog />}></Route>
                    <Route path="/signup/tenants" element={<Tenants />}></Route>
                    <Route path="/login" element={<ReferralsSignUp />}></Route>
                    <Route
                        path="/dashboard"
                        element={<PrivateRoute Component={Dashboard} />}
                    ></Route>
                    <Route
                        path="/leaderboard"
                        element={<PrivateRoute Component={Leaderboard} />}
                    ></Route>
                    <Route
                        path="/profile"
                        element={<PrivateRoute Component={Profile} />}
                    ></Route>
                    <Route
                        path="/wallet"
                        element={<PrivateRoute Component={Wallet} />}
                    ></Route>
                </Routes>
            </AuthProvider>
        </div>
    );
}

export default App;
