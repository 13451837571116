import { Spinner } from "react-bootstrap";
import { Sidebar } from "./Sidebar";

const { useEffect, useState } = require("react")
const { useAuth } = require("../Providers/AuthProvider");
const { Navigate } = require("react-router-dom");

const PrivateRoute = ({ Component }) => {

    const auth = useAuth();

    const [loading, setLoading] = useState(true)
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        auth.checkAuth().then(() => {
            setLoading(false);
            setIsAuthenticated(true);
        }).catch((error) => {
            setLoading(false)
        })
    }, [auth])

    return loading ? <div style={{height: "100vh"}} className="d-flex justify-content-center align-items-center"><Spinner className="mt-5"/></div> : (isAuthenticated ? 
        <Sidebar>
            <Component/> 
        </Sidebar>
    : <Navigate to={"/login"}/>)
}

export default PrivateRoute;