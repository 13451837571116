import { Container } from "react-bootstrap";
import background_stripe_right from "../Icons/background_stripe_right.svg";
import background_stripe_left from "../Icons/background_stripe_left.png";

export const PageCard = ({ children, overlay, withSidebar, cardColour, quotePage, style, signIn, signUp }) => {

  return (
    <Container
      fluid
      className="d-flex"
      style={{
        background: "#F5FAF8",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <img
        src={background_stripe_right}
        className={
          quotePage
            ? "background-stripe-right-quote"
            : signIn ? "background-stripe-right-quote" : `background-stripe-right ${overlay && 'overlay'}`
        }
        alt="background stripe"
      />
      <img
        src={background_stripe_left}
        className={
          quotePage || signIn ? "background-stripe-left-quote" : `background-stripe-left ${overlay && 'overlay'} ${withSidebar && 'with-sidebar'}`
        }
        alt="background stripe"
      />
      <div className={quotePage ? "form-card-quote" : signIn ? "form-card-sign-in" : signUp ? "form-card-sign-up" : "form-card"} style={ style ? {...style, backgroundColor: cardColour || "white"} : {backgroundColor: cardColour || "white"}}>
        {children}
      </div>
    </Container>
  );
};
