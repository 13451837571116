import React, { useEffect, useState } from "react";
import { getLeaderboard } from "../api";
import { PageCard } from "./PageCard"
import { Spinner, Table } from "react-bootstrap";
import crown from "../Icons/crown.png";
import { Coins } from "../Icons/coins.js";
import "./css/Leaderboard.css"
import { LeaderboardRow, shareLink } from "./Dashboard.jsx";
import { useNavigate } from "react-router-dom";

export const Leaderboard = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [nearbyLeaderboard, setNearbyLeaderboard] = useState([]);
  const [myPosition, setMyPosition] = useState("");
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState();
  const [copied, setCopied] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getLeaderboard()
      .then((res) => {
        setLoading(false);
        setLeaderboard(res.leaderboard);
        setUserDetails(res.userDetails)
        findNearbyLeaderboard(res.leaderboard, res.userDetails.id);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          navigate('/login');
          return
        }
        setLoading(false);
        console.log("Error in fetching leaderboard ", err);
      });
  }, [navigate]);

  const findNearbyLeaderboard = (list, id) => {
    const index = list.findIndex((person) => person.id === id);

    setMyPosition(index + 1);

    if (index <= 2) {
      setNearbyLeaderboard(list.slice(0, 5));
    } else if (index >= list.length - 3) {
      setNearbyLeaderboard(list.slice(-5));
    } else {
      setNearbyLeaderboard(list.slice(index - 2, index + 3));
    }
  };

  return loading ? (
    <div className="d-flex justify-content-center align-items-center w-100 h-100">
      <Spinner />
    </div>
  ) : leaderboard.length > 0 ? (
    <PageCard cardColour={"#F5F7FA"} style={{ height: "90vh" }}>
      <div className="col first-column">
        <div className="title">Leaderboard</div>
        <div className="main-leaderboard pt-3">
          <div className="text-start ms-4 fs-5"><b>Global</b></div>
          <div className="podium">
            {leaderboard.length > 2 && <div className="d-flex flex-column justify-content-center align-items-center">
              <div className="podium-position third">
                <img className="podium-image" src={leaderboard[2].image} alt="podium"/>
                <div className="podium-position-indicator position-third">3</div>
              </div>
              <div className="podium-name">{leaderboard[2].name}</div>
              <div className="podium-amount">
                <Coins width={"2em"}/>
                <div>£{leaderboard[2].referral_amount * leaderboard[2].total_tenants}</div>
              </div>
            </div>}
            <div>
              <div className="podium-position first ">
              <img className="podium-image" src={leaderboard[0].image} alt="podium"/>
                <img src={crown} alt="crown" className="crown" />
                <div className="podium-position-indicator position-first">1</div>
              </div>
              <div className="podium-name">{leaderboard[0].name}</div>
              <div className="podium-amount">
                <Coins width={"2em"}/>
                <div>£{leaderboard[0].referral_amount * leaderboard[0].total_tenants}</div>
              </div>
            </div>
            {leaderboard.length > 1  && <div>
              <div className="podium-position second">
              <img className="podium-image" src={leaderboard[1].image} alt="podium"/>

                <div className="podium-position-indicator position-second">2</div>
              </div>
              <div className="podium-name">{leaderboard[1].name}</div>
              <div className="podium-amount">
                <Coins width={"2em"}/>
                <div>£{leaderboard[1].referral_amount * leaderboard[1].total_tenants}</div>
              </div>
            </div>}
          </div>
          <div>
            {leaderboard.slice(3, 20).map((row, index) =>
              <LeaderboardRow row={row} position={index + 4} myPosition={myPosition} />
            )}
          </div>
        </div>
      </div>
      <div className="col mx-4">
        <div style={{ height: "35%" }} className="share section d-flex flex-column align-items-center share-section-leaderboard">
          <div className="share-title">Hello!</div>
          <div className="share-detail">Copy your code below to start earning today.</div>
          <div className="share-detail">Every tenant that signs up earns you <b>£{userDetails.referral_amount}!</b></div>
          <div className="text-start w-100 mt-4"><b>Code</b></div>
          <div className="d-flex w-100">
            <div className="referral-code d-flex flex-column">
              <div className="m-auto">
                {userDetails.referral_code}
              </div>
            </div>
            <div className="copy-button" onClick={() => {shareLink(userDetails.referral_code, setCopied)}}>
              <div>
                {copied ? "Copied!" : "Share"}
              </div>
            </div>
          </div>
        </div>
        <div className="nearby-leaderboard">
          <div className="text-start ms-4 mt-2 fs-5"><b>My Leaderboard</b></div>
          {nearbyLeaderboard.map((row, index) => <LeaderboardRow 
          style={{height: "11%"}}
          position={myPosition <= 2
            ? index + 1
            : myPosition === 3 ? 
              index + 1
              : myPosition >= leaderboard.length - 2
                ? (leaderboard.length - 3 + index)
                : myPosition - 2 + index
            }
          row={row}
          myPosition={myPosition}/>)}
          
        </div>
      </div>
    </PageCard>
  ) : (
    <div className="mt-5">
      <h2 className="mb-5">Leaderboard</h2>
      <Table className="w-75 mx-auto" striped>
        <thead className="">
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Earnings</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>0</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
        </tbody>
      </Table>
      <h5 className="mt-5">
        There are currently zero people on the leaderboard. Start referring now
        to claim your reward.
      </h5>
    </div>
  );
};
