import {Modal} from "react-bootstrap";

export const ReferralTermsAndConditions = () => {
  return (
    <Modal.Body>
      <Modal.Header closeButton className="fw-bold">
        Terms & Conditions
      </Modal.Header>
      <Modal.Body>
        <ol>
          <li className="mb-4">
            The referral scheme is open to undergraduate or postgraduate
            students currently enrolled at a Higher Education institution in the
            United Kingdom.
          </li>
          <li className="mb-4">
            To be eligible for the reward, the referrer’s unique referral code
            must be used when another household signs up for Housr Bills. The
            code can be found in their Housr Bills account.
          </li>
          <li className="mb-4">
            In Manchester and Birmingham, the referred student household must
            not have already been introduced to Housr Bills via their letting
            agent.
          </li>
          <li className="mb-4">
            The referral reward is to be paid out no later than 30 September
            2024. Housr will contact the referrer closer to this date to arrange
            payment.
          </li>
          <li className="mb-4">
            Housr has the right to amend or withdraw this offer at any time.
          </li>
        </ol>
      </Modal.Body>
    </Modal.Body>
  );
};
