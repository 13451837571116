import React, { useState, useEffect } from "react";
import { Form, InputGroup, Button, Alert } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import { ForgotPasswordModal } from "./ReferralsSignUp";
import "./css/Profile.css";
import background_stripe_right from "../Icons/background_stripe_right.png";
import background_stripe_left from "../Icons/background_stripe_left.png";
import { useNavigate } from "react-router-dom";

export const Profile = () => {
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [alert, setAlert] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [userData, setUserData] = useState({
    firstName: null,
    surname: null,
    email: null,
    gender: null,
    number: null,
    city: null,
    password: null,
    confirmPassword: null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    axios.get("/profile", { withCredentials: true }).then((res) => {
      setUserData({
        firstName: res.data.user.name.split(" ")[0],
        lastName: res.data.user.name.split(" ")[1],
        email: res.data.user.email,
        gender: res.data.user.gender,
        number: res.data.user.contact_no,
      });
    }).catch(() => {
      navigate('/login');
    });
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const saveChanges = () => {
    axios
      .post("/profile/save", { user: userData }, { withCredentials: true })
      .then((res) => {
        if (res.status === 204) {
          setShowAlert(true);
          setAlertType("success");
          setAlert("Profile updated successfully.");
        }
      })
      .catch((e) => {
        setShowAlert(true);
        setAlertType("danger");
        setAlert("Something went wrong, please try again later.");
      });
  };

  return (
    <div className="profile-full-container">
      <img
        src={background_stripe_right}
        className="background-stripe-right-profile"
        alt="background stripe"
      />
      <img
        src={background_stripe_left}
        className="background-stripe-left-profile"
        alt="background stripe"
      />
      <div className="profile-container overflow-y-scroll flex-column ">
        <h1 className="signup-header">
          Your Profile
        </h1>
        <div className="flex-direction-profile">
          <Form className="profile-tile">
            <div className="p-4 w-100">
              <Form.Group className="mb-3">
                <div className="d-flex justify-content-between ">
                  <p className="input-title w-50">First Name</p>
                  <p className="input-title w-50 ms-2">Last Name</p>
                </div>
                <InputGroup>
                  <Form.Control
                    id="firstName"
                    name="firstName"
                    className="rounded-2"
                    value={userData.firstName}
                    onChange={handleInputChange}
                  />
                  <Form.Control
                    id="lastName"
                    name="lastName"
                    className="ms-2 rounded-2"
                    value={userData.lastName}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-4">
                <p className="input-title">Email</p>
                <InputGroup>
                  <Form.Control
                    required
                    type="email"
                    name="email"
                    className="rounded-2"
                    placeholder="Email Address"
                    value={userData.email}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email address
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-4">
                <p className="input-title">Gender</p>
                <Form.Select
                  required
                  style={{ color: "black" }}
                  placeholder="Gender"
                  name="gender"
                  value={userData.gender}
                  onChange={handleInputChange}
                >
                  <option value="">Gender</option>
                  <option value={0}>Male</option>
                  <option value={1}>Female</option>
                  <option value={2}>Other</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select an option
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <div className="mb-4 mx-auto w-100">
                  <p className="input-title">Phone Number</p>
                  <PhoneInput
                    placeholder="Enter phone number"
                    className="ms-1"
                    defaultCountry={"GB"}
                    value={userData.number}
                    onChange={(number) =>
                      setUserData({ ...userData, number: number })
                    }
                  />
                </div>
              </Form.Group>
              <div className="d-flex justify-content-evenly pt-2">
                <Button
                  className="housr-button w-50"
                  onClick={() => saveChanges()}
                >
                  Save Changes
                </Button>
              </div>
              <ForgotPasswordModal
                visible={showForgotPasswordModal}
                setVisible={setShowForgotPasswordModal}
                setAlert={setAlert}
                setAlertType={setAlertType}
                setShowAlert={setShowAlert}
              />
              <Alert
                show={showAlert}
                variant={alertType}
                onClose={() => setShowAlert(false)}
                dismissible
                style={{ marginTop: "1vh" }}
              >
                <Alert.Heading>Success</Alert.Heading>
                <p>{alert}</p>
              </Alert>
            </div>
          </Form>
          <div
            className="profile-tile p-4 align-items-center "
            style={{ height: "50%" }}
          >
            <p className="input-title align-self-start ">Password</p>
            <Form.Control type="password" value="hiddenpassword" />
            <Button
              className="housr-button-reverse w-50 mt-5"
              onClick={() => setShowForgotPasswordModal(true)}
            >
              Reset Password
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
