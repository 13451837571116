import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../Providers/AuthProvider";
import "./css/Sidebar.css";
import { Dashboard } from "../Icons/dashboard.js";
import { Leaderboard } from "../Icons/leaderboard.js";
import { Profile } from "../Icons/profile.js";
import { Balance } from "../Icons/balance.js";
import housr_icon from "../Icons/housr_icon.svg";
import menu from "../Icons/menu.svg";

export const Sidebar = ({ children }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const logout = () => {
    auth.logout().finally(() => {
      navigate("/login");
    });
  };

  return (
    <div className="container-fluid">
      <Button className="sidebar-toggle" onClick={() => setSidebarOpen(true)}>
        <img className="menu-toggle-image" src={menu} alt="menu" />
      </Button>
      <div className="row flex-nowrap">
        <div
          className={`col-auto col-md-3 col-xl-2 px-sm-2 px-0 sidebar-container ${
            sidebarOpen && "open"
          }`}
        >
          <div className="sidebar-close" onClick={() => setSidebarOpen(false)}>
            &times;
          </div>
          <div className="pt-5 text-white min-vh-100">
            <img src={housr_icon} className="sidebar-logo" alt="Housr logo" onClick={() => {window.location.replace('https://www.housrapp.co.uk')}}/>
            <div
              className="mt-5 w-100"
              style={{ height: "90vh", position: "relative" }}
              id="menu"
            >
              <SidebarLink
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                path="/dashboard"
                label="Dashboard"
                active={pathname === "/dashboard"}
                icon={
                  <Dashboard
                    className="sidebar-icon"
                    fill={pathname === "/dashboard" ? "green" : "grey"}
                  />
                }
              />
              <SidebarLink
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                path="/leaderboard"
                label="Leaderboard"
                active={pathname === "/leaderboard"}
                icon={
                  <Leaderboard
                    className="sidebar-icon"
                    fill={pathname === "/leaderboard" ? "green" : "grey"}
                  />
                }
              />
              <SidebarLink
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                path="/profile"
                label="Profile"
                active={pathname === "/profile"}
                icon={
                  <Profile
                    className="sidebar-icon"
                    fill={pathname === "/profile" ? "green" : "grey"}
                  />
                }
              />
              <SidebarLink
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                path="/wallet"
                label="Wallet"
                active={pathname === "/wallet"}
                icon={
                  <Balance
                    className="sidebar-icon"
                    fill={pathname === "/wallet" ? "green" : "grey"}
                  />
                }
              />
              <hr />
              <div
                style={{ position: "absolute", bottom: "15%", width: "100%" }}
              >
                <Button className="sidebar-button" onClick={logout}>
                  Log Out
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="col py-3" style={{ paddingRight: 0, paddingLeft: 0 }}>
          {children}
        </div>
      </div>
    </div>
  );
};

const SidebarLink = ({
  path,
  label,
  icon,
  active,
  setSidebarOpen,
  sidebarOpen,
}) => {
  return (
    <Link
      className={`sidebar-link ${active && "active"}`}
      to={path}
      onClick={() => {
        if (sidebarOpen) setSidebarOpen(false);
      }}
    >
      {icon}
      <span className="ms-2 m-auto">{label}</span>{" "}
    </Link>
  );
};
