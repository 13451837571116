import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Modal
} from "react-bootstrap";
import information from "../Icons/information.svg";
import referral from "../Icons/wallet_referral.svg";
import earnings from "../Icons/earnings.png";
import pending from "../Icons/pending.svg";
import wallet_withdrawn from "../Icons/wallet_withdrawn.svg";
import { shareLink } from "./Dashboard";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import "./css/Wallet.css";
import { ReferralTermsAndConditions } from "./ReferralTermsAndConditions";

const Wallet = () => {
  const navigate = useNavigate();

  const [activities, setActivities] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [earningsData, setEarningsData] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [showTC, setShowTC] = useState(false)

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      const response = await axios.get("/wallet", { withCredentials: true });
      const userResponse = await axios.get("/user", { withCredentials: true });
      setUserDetails(userResponse.data.userDetails);
      setActivities(response.data.activities);
      organiseEarnings(
        response.data.activities,
        userResponse.data.userDetails.referral_amount
      );
      setLoading(false);
    } catch (error) {
      if (error.response?.status === 401) {
        navigate("/login");
        return;
      }
      setAlert(true);
    }
  };

  const convertTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const splitDate = date.toString().split(" ");

    return splitDate.splice(1, 3).join(" ");
  };

  const organiseEarnings = (activities, referralAmount) => {
    const earnings = activities.filter((activity) => activity.signed_at);

    const earningsCount = {};

    earnings.map((earning) => {
      const date = new Date(earning.signed_at);

      const month = date.getMonth();
      const year = date.getFullYear();

      if (year == new Date().getFullYear()) {
        if (earningsCount[month]) {
          earningsCount[month]++;
        } else {
          earningsCount[month] = 1;
        }
      }
    });

    let updatedEarningsData = [...earningsData];

    for (const month in earningsCount) {
      updatedEarningsData[+month] = earningsCount[+month] * referralAmount;
    }

    setEarningsData(updatedEarningsData);
  };

  const TermsConditionsModal = () => {
    return (
      <Modal
        show={showTC}
        onHide={() => {
          setShowTC(false);
        }}
        className="d-flex flex-column"
      >
        <ReferralTermsAndConditions/>
      </Modal>
    );
  };

  const AvailableCard = () => {
    return (
      <Card
        style={{ width: "90%", borderRadius: "20px", height: "fit-content" }}
        className="border justify-content-start mt-lg-5 mt-3 wallet-card"
      >
        <div className="d-flex flex-column">
          <Card.Body className="d-flex available-card-body">
            <div className="d-flex align-items-center me-lg-5">
              <img alt="coins" src={earnings} style={{ height: "80%" }} />
            </div>
            <div
              className="d-flex justify-content-start align-self-center flex-column"
              style={{ height: "80%" }}
            >
              <p className="text-start fw-bold fs-lg-6 fs-4 mb-0 d-flex align-items-center me-lg-0 me-2">
                Available
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      Subject to your referees fully completing the bills signup process. This includes making their first payment.
                    </Tooltip>
                  }
                >
                  <Button
                    style={{
                      backgroundColor: "transparent",
                      borderWidth: "0px",
                    }}
                    className="p-0 ms-2 d-flex align-items-center pending-information"
                  >
                    <img
                      alt="information"
                      src={information}
                      style={{ height: "65%" }}
                    />
                  </Button>
                </OverlayTrigger>
              </p>
              <p
                className="text-start fw-bold fs-1 d-flex align-items-center my-auto"
                style={{ color: "#109539" }}
              >
                £{(userDetails.referral_amount * userDetails.signed_tenants).toFixed(2)}
              </p>
            </div>
            <div
              className="my-auto ms-lg-4 ms-2"
              style={{ width: "100%" }}
            >
              <Button
                className="w-75 fw-bold withdraw-button"
                style={{
                  backgroundColor: "#109539",
                  borderColor: "#109539",
                  borderRadius: "20px",
                }}
                disabled
              >
                Withdraw
              </Button>
            </div>
          </Card.Body>
          <Card.Text
            style={{ width: "90%" }}
            className="mx-auto text-start pb-3"
          >
            Come back from 15th September 2024 when you'll be able to withdraw
            all of your earnings in time for freshers! Subject to <a
                      style={{ color: "#109539", cursor: "pointer", textDecoration: "none" }}
                      className="fw-bold"
                      onClick={() => {
                        setShowTC(true);
                      }}
                    >
                      Terms & Conditions.
                    </a>
          </Card.Text>
        </div>
      </Card>
    );
  };

  const SmallCard = ({ title, img, withdrawn, amount }) => {
    return (
      <Card
        style={{
          width: "47.5%",
          borderRadius: "20px",
          marginRight: withdrawn ? "5%" : undefined,
        }}
      >
        <Card.Body className="d-flex align-items-center justify-content-start wallet-small-card-body">
          <div className="h-100 align-items-center d-flex me-5 image-container">
            <img alt="withdrawn" src={img} style={{ height: "80%" }} />
          </div>
          <div
            className="d-flex justify-content-center align-self-center flex-column"
            style={{ height: "80%" }}
          >
            <div className="text-start fw-bold fs-6 mb-0 wallet-small-title d-flex">
              {title}
              {!withdrawn && (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      Amount to be available once all students with your
                      referral code have signed their utility form
                    </Tooltip>
                  }
                >
                  <Button
                    style={{
                      backgroundColor: "transparent",
                      borderWidth: "0px",
                    }}
                    className="p-0 ms-2 d-flex align-items-center pending-information"
                  >
                    <img
                      alt="information"
                      src={information}
                      style={{ height: "65%" }}
                    />
                  </Button>
                </OverlayTrigger>
              )}
            </div>
            <p
              className="text-start fw-bold fs-2 wallet-money pb-0 mb-0"
              style={{ color: withdrawn ? "#951010" : "#10958D" }}
            >
              £{amount.toFixed(2)}
            </p>
          </div>
        </Card.Body>
      </Card>
    );
  };

  const ChartCard = () => {
    return (
      <Card
        style={{ width: "90%", borderRadius: "20px" }}
        className="mt-3 wallet-card"
      >
        <Card.Body>
          <div className="justify-content-start d-flex flex-column ps-2">
            <Card.Title className="text-start fw-bold">Overview</Card.Title>
            <Card.Text className="text-start mb-3" style={{ color: "#A9ACB0" }}>
              View your referral earnings overtime
            </Card.Text>
          </div>
          <Bar
            data={{
              labels: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "June",
                "July",
                "Aug",
                "Sept",
                "Oct",
                "Nov",
                "Dec",
              ],
              datasets: [
                {
                  data: earningsData,
                  backgroundColor: "#75BA81",
                  borderRadius: 5,
                },
              ],
            }}
            options={{
              plugins: {
                tooltip: {
                  enabled: false,
                },
                legend: {
                  display: false,
                },
              },
              title: {
                display: false,
              },
              scales: {
                y: {
                  title: {
                    display: true,
                    text: "Earnings (£)",
                  },
                  grid: {
                    display: true,
                  },
                },
                x: {
                  title: {
                    display: true,
                    text: new Date().getFullYear(),
                  },
                  grid: {
                    display: false,
                  },
                },
              },
            }}
          />
        </Card.Body>
      </Card>
    );
  };

  const ShareCard = () => {
    return (
      <Card style={{ borderRadius: "20px" }} className="share-card">
        <Card.Body
          className="justify-content-start mx-auto text-start"
          style={{ width: "80%" }}
        >
          <Card.Title className="fw-bold fs-3" style={{ color: "#109539" }}>
            Want to keep earning more?
          </Card.Title>
          <Card.Text className="mb-1">
            Copy your code below to start earning today.
          </Card.Text>
          <div className="">
            Every tenant that signs up earns you<b className="ps-1">£{userDetails.referral_amount}!</b>
          </div>
          <Card.Text className="fw-bold mt-2 mb-0">Code</Card.Text>
          <div className="d-flex w-100">
            <div className="referral-code d-flex flex-column">
              <div className="m-auto">{userDetails.referral_code}</div>
            </div>
            <div
              className="copy-button w-25"
              onClick={() => {
                shareLink(userDetails.referral_code, setCopied);
              }}
            >
              <div>{copied ? "Copied!" : "Share"}</div>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  const ActivityCard = () => {
    return (
      <Card
        className="mt-4 activity-card mb-lg-3 mb-5"
        style={{
          borderRadius: "20px",
          width: "50%",
          minHeight: "60vh",
        }}
      >
        <Card.Body
          style={{ height: "80%", width: "90%" }}
          className="mx-auto justify-content-start d-flex flex-column"
        >
          <Card.Title
            className="fw-bold fs-5 mb-4 text-start pt-3 ps-1"
            style={{ width: "90%" }}
          >
            Recent Activity
          </Card.Title>

          {activities.length > 0 ?
            activities?.map((activity) => {
              return (
                <Card
                  style={{
                    backgroundColor: "#F5F7FA",
                    borderRadius: "12px",
                  }}
                  className="mb-3"
                  key={"activity" + activity.id}
                >
                  <Card.Body className="d-flex justify-content-evenly activity-referral-card">
                    <div className="h-75 my-auto">
                      <img alt="referrals" src={referral} className="h-100" />
                    </div>
                    <div className="ps-3">
                      <Card.Title className="mb-0 text-start fw-bold">
                        Referral
                      </Card.Title>
                      <Card.Text className="text-start wallet-referral-date">
                        {convertTimestamp(activity.updated_at)}
                      </Card.Text>
                    </div>
                    <div className="align-items-center d-flex justify-content-end">
                      {activity.status == 2 ? (
                        <Card.Text
                          className="fw-bold fs-5"
                          style={{ color: "#951010" }}
                        >
                          -£{(+userDetails.referral_amount).toFixed(2)}
                        </Card.Text>
                      ) : (
                        <Card.Text
                          className="fw-bold fs-5"
                          style={{ color: "#109539" }}
                        >
                          +£{(+userDetails.referral_amount).toFixed(2)}
                        </Card.Text>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              );
            }) : 
            <h1 className="fs-6">You have no recent activity</h1>
              }
        </Card.Body>
      </Card>
    );
  };

  return (
    <div style={{ overflowY: "scroll", height: "100vh" }}>
      {alert ? (
        <Alert variant="danger" className="w-75 m-auto">
          <Alert.Heading>Error</Alert.Heading>
          Something went wrong. Please try again later.
        </Alert>
      ) : loading ? (
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <Spinner />
        </div>
      ) : (
        <div className="container">
          <TermsConditionsModal/>
          <div className="w-100 d-lg-flex" style={{ paddingTop: "50px" }}>
            <div className="d-flex flex-column w-50 pb-4 wallet-col">
              <h1 className="text-start w-100 fw-bold">Your Wallet</h1>
              <AvailableCard />
              <div
                className="d-flex flex-row mt-4 small-card-container"
                style={{ width: "90%" }}
              >
                <SmallCard
                  title={"Withdrawn"}
                  img={wallet_withdrawn}
                  withdrawn
                  amount={
                    userDetails.referral_amount * userDetails.withdrawn_tenants
                  }
                />
                <SmallCard
                  title={"Pending"}
                  img={pending}
                  amount={
                    userDetails.referral_amount * userDetails.pending_tenants
                  }
                />
              </div>
              <ChartCard />
            </div>
            <div className="d-flex flex-column w-50 wallet-col">
              <ShareCard />
              <ActivityCard />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Wallet;
