import axios from "axios";
import { useContext, createContext } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {

    const login = (data) => {
        return axios.post('/auth/login', data, { withCredentials: true })
    }

    const logout = () => {
        return axios.post('/auth/logout', {}, { withCredentials: true })
    }

    const checkAuth = () => {
        return axios.get("/auth", { withCredentials: true });
    }

    const signup = (data) => {
        return axios.post('/referralSignUp', data, { withCredentials: true })
    }

    return <AuthContext.Provider value={{ login, logout, signup, checkAuth }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};